import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteSavedSearch, getSavedSearches, saveSearch } from "../api/newSearch";
import { SavedSearch } from "../models/savedSearch";
import { SaveSearchResponse, SearchFilters } from "../models/search";
import { defaultFilterState } from "../constants/defaultFilterState";
import useUserContext from "../components/Authorization/useUserContext";

export default function useSavedSearch() {
  const user = useUserContext();

  const {
    data: savedSearches,
    refetch: refetchSavedSearches,
    isLoading: fetchingSavedSearches,
  } = useQuery<SavedSearch[]>({
    queryFn: () => getSavedSearches(user),
    queryKey: ["savedSearches"],
  });

  const getSavedSearch = (searchId: string) => {
    if (!savedSearches) {
      console.warn("useSavedSearch.getSavedSearch(): savedSearches undefined", {
        savedSearches,
        fetchingSavedSearches,
      });
      return null;
    }

    const savedSearch = savedSearches.find(
      ({ search_id: savedSearchId }: { search_id: string }) => savedSearchId === searchId,
    );

    if (savedSearch) {
      const selectedSavedFilter = {
        ...defaultFilterState,
        ...savedSearch,
      };
      return selectedSavedFilter;
    } else return null;
  };

  // Save search mutation
  const searchesMutation = useMutation({
    mutationFn: ({ viewName, filters }: { viewName: string; filters: SearchFilters }): Promise<SaveSearchResponse> => {
      return saveSearch(viewName, filters, user);
    },
    onSuccess: () => {
      // Refetch list of saved searches
      refetchSavedSearches();
    },
  });
  const { mutate: onSaveSearch, isLoading: isSavingSearch } = searchesMutation;

  // Delete a saved search mutation
  const deleteSearchMutation = useMutation({
    mutationFn: ({ savedSearchIds }: { savedSearchIds: string[] }): Promise<string[]> => {
      return Promise.all(savedSearchIds.map((id) => deleteSavedSearch(id, user)));
    },
    onSuccess: () => {
      // Refetch the list of saved searches
      refetchSavedSearches();
    },
  });
  const { mutate: onDeleteSavedSearch, isLoading: isDeletingSearch } = deleteSearchMutation;

  const isValidSavedFilterName = (viewName: string): boolean => {
    // Case insensitive comparison of filter name
    return (savedSearches || []).every(
      (filter) => viewName.localeCompare(filter.title, "en", { sensitivity: "base" }) !== 0,
    );
  };

  return {
    savedSearches,
    getSavedSearch,
    onSaveSearch,
    isSavingSearch,
    refetchSavedSearches,
    fetchingSavedSearches,
    onDeleteSavedSearch,
    isDeletingSearch,
    isValidSavedFilterName,
    isLoading: isSavingSearch || fetchingSavedSearches || isDeletingSearch,
  };
}
