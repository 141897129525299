import { History } from "@mui/icons-material";
import { Box, PaperProps, Typography } from "@mui/material";
import dayjs from "dayjs";

const recentDocuments = [
  {
    filename: "Document1.pdf",
    status: "emailed",
    upload_timestamp: new Date(),
  },
  {
    filename: "Document2.pdf",
    status: "processing",
    upload_timestamp: new Date(),
  },
  {
    filename: "Document3.pdf",
    status: "processing",
    upload_timestamp: new Date(),
  },
];

export default function RecentDocuments({ sx, ...props }: PaperProps) {
  return (
    <Box
      sx={{
        ...sx,
        ...{
          "& .document-list": {
            marginBottom: "1em",

            "& .title": {
              fontWeight: "bold",
            },
            "& .status": {
              textTransform: "capitalize",
            },

            "& .details": {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            },
          },
        },
      }}
    >
      <Box>
        <History sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
        <Typography variant="h6" color={"#13294B"} display="inline-block">
          {" "}
          Recent Documents
        </Typography>
      </Box>
      <Box sx={{ mt: "1em" }}>
        {recentDocuments.map((document, i) => {
          return (
            <Box className="document-list" key={i}>
              <Typography className="title">{document.filename}</Typography>
              <Box className="details">
                <Typography className="status">{document.status}</Typography>
                <Typography className="timestamp">
                  {dayjs(document.upload_timestamp).format("M/D/YYYY h:m A")}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
