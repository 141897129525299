import { Box, PaperProps, Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { getInquiryVolumeOverTime } from "../../api/dataSources";
import ChartCard from "../../components/ChartCard";
import { DownloadToolTipContent, LineChart, formatIds } from "../../components/charts";
import { ChartMenu, DownloadButton, FullScreen } from "../../components/common";
import { defaultFilterState } from "../../constants/defaultFilterState";
import { useFilterContext } from "../../contexts/FilterProvider";
import { FilterResetAtom } from "../../contexts/FilterResetAtom";
import { InquiryVolumeOverTimeResponse } from "../../models/datasource";
import { downloadAsPng } from "../../utils/element-export";
import CommonTooltipContent from "../CommonTooltipContent";
import useUserContext from "../Authorization/useUserContext";

const InquiryVolumeOverTimeCard = (props: PaperProps): JSX.Element => {
  const user = useUserContext();
  const { filterState } = useFilterContext();
  const [filterReset] = useAtom(FilterResetAtom);

  const ref = useRef<HTMLCanvasElement>(null);
  const [expanded, setExpanded] = useState(false);

  const [selectedTopic, setSelectedTopic] = useState<string | undefined>(undefined);

  const triggerDownload = () => {
    if (ref.current) {
      downloadAsPng(ref.current, "inquiry_volume_over_time");
    }
  };

  useEffect(() => {
    setSelectedTopic(undefined);
  }, [filterReset]);

  const {
    data: dataResponse,
    isLoading,
    isError,
  } = useQuery<InquiryVolumeOverTimeResponse>({
    queryKey: ["inquiry_volume_over_time", JSON.stringify(filterState), user.selectedRoles],
    queryFn: () => getInquiryVolumeOverTime(filterState || defaultFilterState, user),
    enabled: !!filterState,
  });

  const data = !isError && dataResponse?.data ? dataResponse.data : [];

  const labelMap: { [key: string]: string } = {
    inquiry_count: "Inquiries",
    customer_count: "Customers",
  };

  return (
    <ChartCard
      {...props}
      title={["Inquiry & Customer Volume Over Time"]}
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      tooltipContent={
        <CommonTooltipContent
          header="Inquiry & Customer Volume Over Time"
          desc={
            <>
              The Inquiry & Customer Volume Over Time widget represents the change in volume of both customers and
              inquiries over the date range selected, based on your search and filter parameters.
            </>
          }
        />
      }
      actions={
        <ChartMenu
          onDownload={triggerDownload}
          downloadToolTip={<DownloadToolTipContent />}
          onFullScreen={() => setExpanded(true)}
        />
      }
    >
      {isLoading ? (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
          {[1, 2, 3, 4, 5, 6].map((value) => (
            <Skeleton key={value} variant="rounded" height={20} width={"80%"} sx={{ marginTop: "12px" }} />
          ))}
        </Box>
      ) : (
        <FullScreen
          title="Inquiry & Customer Volume Over Time"
          expanded={expanded}
          handleClose={() => setExpanded(false)}
          p={"1em"}
          actions={<DownloadButton action={triggerDownload} />}
        >
          <LineChart
            onSelectTopic={(topic: string) => setSelectedTopic(topic === selectedTopic ? undefined : topic)}
            selectedTopic={selectedTopic}
            ref={ref}
            data={formatIds(data, labelMap)}
            isFullScreen={expanded}
          />
        </FullScreen>
      )}
    </ChartCard>
  );
};

export default InquiryVolumeOverTimeCard;
