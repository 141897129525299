import { useAtom } from "jotai";
import { useMemo } from "react";
import { QuestionIsLoadingAtom, QuestionTextAtom } from "../../components/Deepchat/state/DeepchatState";
import useLocalFilterState from "../../components/filters/useLocalFilterState";
import { useDeepChatSocketContext } from "../../contexts/DeepChatSocketContext";
import { useFilterContext } from "../../contexts/FilterProvider";
import useDeepchat from "../../contexts/useDeepchat";
import { commercialQuestions, medicalQuestions } from "../../constants/sampleQuestions";
import useUserContext from "../../components/Authorization/useUserContext";

export const useSampleQuestionStore = () => {
  const [loading, setLoading] = useAtom(QuestionIsLoadingAtom);
  const [, setQuestion] = useAtom(QuestionTextAtom);
  const { askQuestionStreaming } = useDeepchat();
  const { filterState, applyFilters } = useFilterContext();
  const DeepChatSocket = useDeepChatSocketContext();
  const { transientFilterState } = useLocalFilterState(filterState);
  const user = useUserContext();

  const questions = user.selectedRoles?.includes("commercial")
    ? commercialQuestions
    : user.selectedRoles?.includes("medical")
      ? medicalQuestions
      : [];

  const getRandomSubset = <T>(arr: T[], count: number): T[] => {
    return arr
      .map((x) => ({ x, r: Math.random() }))
      .sort((a, b) => a.r - b.r)
      .map((a) => a.x)
      .slice(0, count);
  };
  const randomQuestion = useMemo(() => getRandomSubset(questions, 3), []);

  const submitQuestion = (question: string) => {
    if (!loading) {
      setLoading(true);
      setQuestion(question);
      if (transientFilterState) {
        applyFilters(transientFilterState);
      }
      setTimeout(() => {
        askQuestionStreaming(DeepChatSocket.sendJsonMessage, transientFilterState ?? filterState, question);
      }, 200);
    }
  };

  return {
    randomQuestion,
    submitQuestion,
  };
};
