import { Box, Drawer, Grid, Skeleton } from "@mui/material";
import { SavedSearchesDropdown } from ".";
import { useFilterContext } from "../contexts/FilterProvider";
import useFilterOptions from "../contexts/useFilterOptions";
import { DataSourceFilter, Filter, FilterResponse } from "../models/filter";
import { SearchFilters, SearchResults } from "../models/search";
import FilterSectionVirtual from "./FilterSectionVirtual";
import { ClearFiltersButton, DateRangeFilter } from "./filters";
import ApplyFiltersButton from "./filters/ApplyFiltersButton";
import CustomerTypeTreeFilter from "./filters/CustomerTypeTreeFilter";
import { getFilter, updateFilter } from "./filters/FilterFunctions";
import NationalAccountTreeFilter from "./filters/NationalAccountTreeFilter";
import useLocalFilterState from "./filters/useLocalFilterState";
import useUserContext from "./Authorization/useUserContext";
import Roles from "./Authorization/Roles";
import PortfolioQuarterFilter from "./PortfolioQuarterFilter";
import PortfolioViewSummaryButton from "./PortfolioViewSummaryButton";

export const FILTER_WIDTH = 300;
const BOTTOM_BOX_HEIGHT = 50;

// function that takes in the datasource filters and current filter state and returns the
// unique set of filters by datatype. Some data sources may have duplicate filters with different values;
// the values will be combined into a single set and contained under a single filter, e.g.
// datasourceA: {column_name: "a_filter", filter_values: ["value1", "value2"]}
// datasourceB: {column_name: "a_filter", filter_values: ["value1", "value3"]}
// resulting filter will have values ["value1", "value2", "value3"]
const getActiveFiltersByDataType = (
  filtersByDatatype: DataSourceFilter[] | undefined,
  filterState: SearchFilters | undefined,
): Filter[] => {
  const activeFilters: Filter[] = [];

  if (!filtersByDatatype || !filterState) return activeFilters;

  filtersByDatatype.forEach((filter) => {
    if (filterState.base_filters.data_source?.includes(filter.data_source_name)) {
      filter.data_source_filters.forEach((f) => {
        const existingItem = activeFilters.find((activeFilter) => activeFilter.column_name === f.column_name);
        if (existingItem && existingItem.filter_values) {
          // filter has already been added to activeFilters from another data source; ensure that a unique
          // set of values is created from the existing activeFilter with the new values
          existingItem.filter_values = [...new Set([...existingItem.filter_values, ...(f.filter_values || [])])];
        } else {
          // filter has not been added to activeFilters
          activeFilters.push(f);
        }
      });
    }
  });
  return activeFilters;
};

interface FilterDrawerFiltersProps {
  filterOptions: FilterResponse | undefined;
  /** Workspace [local] variable for user selection */
  filterState: SearchFilters | undefined;
  /** Filters applied to the search [Verbatims] context */
  appliedFilterState: SearchFilters | undefined;
  handleFilterChange: (filterKey: keyof SearchResults, value: string[]) => void;
  disabled: boolean;
}

const FilterDrawerFilters = ({
  filterOptions,
  filterState,
  appliedFilterState,
  disabled,
  handleFilterChange,
}: FilterDrawerFiltersProps) => {
  const user = useUserContext();

  if (!filterOptions || !filterState || !appliedFilterState) return <></>;

  const userRole = user.selectedRoles;
  const showNewNationalAccountFilter = userRole?.includes("medical");
  // NOTE: We are not doing this at this time but WILL be doing it in the near future - Eric 20240321
  const showNewHcpFilter = false;

  if (showNewHcpFilter) {
    // Strip out "account_type" filter since its now a child of the customer type filter
    filterOptions.common_filters = (filterOptions?.common_filters || []).filter(
      (_filter) => !["account_type"].includes(_filter.column_name),
    );
  }

  const activeFiltersByDataType = getActiveFiltersByDataType(filterOptions?.data_source_filters, filterState);

  let response = [<Skeleton height={100} variant="rounded" sx={{ margin: "4px 2px 0px 8px" }} />];

  let allFilters: Filter[] = [];

  if (filterOptions && filterState) {
    // sorting active filters within their groups - common filters and filters by data type
    allFilters = [
      // Don't sort the common filters as they are returned from the server to be displayed in a specific order
      ...(filterOptions?.common_filters ?? []),

      ...activeFiltersByDataType.sort((a, b) => (a.column_name < b.column_name ? -1 : 1)),
    ];

    response = allFilters.map((filter) => {
      // Custom handler for the "Tree View" of Account > Corp Account
      if (showNewHcpFilter && filter.column_name === "customer_type") {
        return (
          <CustomerTypeTreeFilter
            disabled={disabled}
            key={"Customer Type"}
            filterOptions={filterOptions}
            filterState={filterState}
            appliedFilterState={appliedFilterState}
            handleFilterChange={handleFilterChange}
          />
        );
      } else if (showNewNationalAccountFilter && filter.column_name === "corp_account") {
        return (
          <NationalAccountTreeFilter
            disabled={disabled}
            key={"Corp Account"}
            filterOptions={filterOptions}
            filterState={filterState}
            appliedFilterState={appliedFilterState}
            handleFilterChange={handleFilterChange}
          />
        );
      }
      // "Normal" single property filters
      else {
        return (
          <FilterSectionVirtual
            key={filter.filter_name}
            filter={filter}
            appliedValues={getFilter(appliedFilterState, filter.column_name)}
            selectedValues={getFilter(filterState, filter.column_name)}
            disabled={disabled}
            handleFilterChange={handleFilterChange}
          />
        );
      }
    });
  }

  return response;
};

const FilterDrawerControls = ({
  hasActiveFilters,
  hasUnappliedFilterChanges,
  applyFilters,
  showApplyFilters,
}: {
  hasActiveFilters: boolean;
  hasUnappliedFilterChanges: boolean;
  showApplyFilters: boolean;
  applyFilters: () => void;
}) => {
  return (
    (hasActiveFilters || hasUnappliedFilterChanges) && (
      <Box
        sx={{
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={showApplyFilters ? 6 : 12}>
            <ClearFiltersButton height={BOTTOM_BOX_HEIGHT} />
          </Grid>
          {showApplyFilters && (
            <Grid item xs={6}>
              <ApplyFiltersButton
                height={BOTTOM_BOX_HEIGHT}
                hasUnappliedFilterChanges={hasUnappliedFilterChanges}
                applyFilters={applyFilters}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    )
  );
};

type FilterDrawerProps = {
  topPadding: number;
  hideFilterDrawer?: boolean;
  disableFilters?: boolean;
  showApplyFilters?: boolean;
};

const FilterDrawer = ({
  topPadding,
  hideFilterDrawer,
  disableFilters = false,
  showApplyFilters = true,
}: FilterDrawerProps): JSX.Element => {
  const user = useUserContext();
  const isUserUSLT = user.selectedRoles?.at(0) == Roles.USLT_RETRO;

  disableFilters = disableFilters ?? false;
  const { data: filterOptions } = useFilterOptions();

  const { filterState, hasActiveFilters, applyFilters } = useFilterContext();
  const { transientFilterState, setTransientFilterState, hasUnappliedFilterChanges } = useLocalFilterState(filterState);

  const handleFilterChange = (filterKey: keyof SearchResults, value: string[]) => {
    if (!filterOptions) return;

    setTransientFilterState((_localFilterState) => updateFilter(_localFilterState, filterOptions, filterKey, value));
  };

  const applyLocalFilters = () => {
    applyFilters(transientFilterState);
  };

  const notHideable = undefined;

  return (
    <Drawer
      open={!hideFilterDrawer}
      sx={{
        width: hideFilterDrawer === notHideable ? FILTER_WIDTH : undefined,
        "& .MuiDrawer-paper": {
          width: FILTER_WIDTH,
          boxSizing: "border-box",
          paddingTop: `${topPadding}px`,
          borderRight: "none",
        },
      }}
      variant={hideFilterDrawer === notHideable ? "permanent" : "persistent"}
      anchor="left"
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
          borderRight: "1px solid #eeeeee",
        }}
      >
        {isUserUSLT ? (
          <>
            <div style={{ padding: "4px 8px 4px 8px" }}>
              <PortfolioViewSummaryButton />
            </div>
            <div style={{ padding: "4px 8px 4px 8px" }}>
              <PortfolioQuarterFilter />
            </div>
          </>
        ) : (
          <Box sx={disableFilters ? { "& *": { cursor: "not-allowed", "& *": { pointerEvents: "none" } } } : undefined}>
            <div style={{ padding: "10px 8px 4px 8px ", ...(disableFilters && { opacity: "50%" }) }}>
              <SavedSearchesDropdown />
            </div>
            <div style={{ padding: "4px 8px 4px 8px" }}>
              {filterOptions ? <DateRangeFilter /> : <Skeleton height={40} variant="rounded" />}
            </div>
          </Box>
        )}
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          sx={{
            overflowY: "auto",
          }}
        >
          <FilterDrawerFilters
            disabled={disableFilters}
            {...{
              filterState: transientFilterState,
              appliedFilterState: filterState,
              filterOptions,
              handleFilterChange,
            }}
          />
        </Box>
      </Box>
      {!disableFilters && (
        <FilterDrawerControls
          {...{ hasUnappliedFilterChanges, hasActiveFilters, applyFilters: applyLocalFilters, showApplyFilters }}
        />
      )}
    </Drawer>
  );
};

export default FilterDrawer;
