import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useUserContext from "../components/Authorization/useUserContext";
import { CustomTab } from "../models/customTabs";
import { buildHeaders } from "./utils";

const API_URI = import.meta.env.VITE_APP_API_URI;

const queryKey = ["customTabs"];

export const useCustomTabs = () => {
  const queryClient = useQueryClient();
  const user = useUserContext();

  const fetchCustomTabs = async (): Promise<CustomTab[]> => {
    const response = await axios.get<{ tabs: CustomTab[] }>(`${API_URI}/custom-tabs`, {
      headers: buildHeaders(user),
    });
    return response?.data?.tabs ?? [];
  };

  const updateCustomTabs = async (tabs: CustomTab[]) => {
    const payload = {
      tabs: tabs,
    };

    return await axios.post(`${API_URI}/custom-tabs`, payload, {
      headers: buildHeaders(user),
    });
  };

  const { data, isLoading } = useQuery({ queryKey, queryFn: fetchCustomTabs });

  const { mutate } = useMutation({
    mutationFn: updateCustomTabs,
    onMutate: async (newState: CustomTab[]) => {
      await queryClient.cancelQueries({ queryKey });
      const previousTabs = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, newState);
      return { previousTabs };
    },
    onError: (_err, _newTodo, context) => {
      if (context?.previousTabs) {
        queryClient.setQueryData(queryKey, context.previousTabs);
      }
    },
  });

  return { customTabs: data ?? [], setCustomTabs: mutate, isLoading };
};
