import { Box, Stack, Typography } from "@mui/material";
import { useAskQuestionStore } from "../../../store/home/useAskQuestionStore";
import QuestionInput from "./QuestionInput";
import RecentThreads from "./RecentThreads";
import SampleQuestions from "./SampleQuestions";
import { useQuestionEnhancer } from "../../../store/home/useQuestionEnhancer";
import { useLocation } from "react-router-dom";
import { QuestionTextAtom } from "../state/DeepchatState";
import { useAtom } from "jotai";
import { useEffect } from "react";
import DeepchatNavigationTabs from "../DeepchatNavigationTabs";
import { Feature } from "../../../featureFlags/featureFlagConfig";
import useFeatureFlag from "../../../featureFlags/useFeatureFlag";

export default function AskQuestion() {
  const document_summary = useFeatureFlag(Feature.DocumentSummary);
  useAskQuestionStore();

  const [, setQuestion] = useAtom(QuestionTextAtom);
  const location = useLocation();
  const { enhanceQuestion, QE } = useQuestionEnhancer();

  useEffect(() => {
    // If we navigated back for an immediate QE, and there are not already enhancement options present
    if (location.state?.trigger_enhance && location.state?.user_query && (QE.options ?? []).length == 0) {
      // Set and trigger the QE to load
      setQuestion(location.state?.user_query);
      enhanceQuestion(location.state?.user_query);
    }
  }, []);

  return (
    <>
      <Box flex={1} minHeight="100%" marginX="auto" maxWidth="48rem">
        <Stack
          className="QuestionInput"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ minHeight: "100%" }}
        >
          <Typography variant="h3" fontWeight="bold" color={"rgb(0, 48, 135)"}>
            Deepchat
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {document_summary.enabled && <DeepchatNavigationTabs />}
            <QuestionInput />
          </Box>
          <SampleQuestions />
        </Stack>
      </Box>
      <Box sx={{ width: "24vw", px: "2em", ml: "2em", borderLeft: "1px solid #eee", overflow: "auto" }}>
        <RecentThreads />
      </Box>
    </>
  );
}
