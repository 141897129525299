import { Box, Collapse, Skeleton, Typography as T } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getDataDistributions } from "../../api/dataSources";
import { ChartCard } from "../../components";
import DataCompletenessTable from "../../components/charts/DataCompletenessTable";
import StyledButton from "../../components/common/StyledButton";
import { defaultFilterState } from "../../constants/defaultFilterState";
import { useFilterContext } from "../../contexts/FilterProvider";
import { DataDistribution } from "../../models/datasource";
import { compactFormatter } from "../../utils/numberFormatter";
import { DataDistributionChart, DataRefreshTable } from "../charts";
import CommonTooltipContent from "../CommonTooltipContent";
import useUserContext from "../Authorization/useUserContext";

const DataDistributionCard = (): JSX.Element => {
  const user = useUserContext();
  const { filterState } = useFilterContext();

  const [expanded, setExpanded] = useState<boolean>(false);

  const getTotalRecords = (data: DataDistribution[]) => {
    return data.reduce((sum, d) => d.record_count + sum, 0);
  };

  const {
    data: dataResponse,
    isLoading,
    isError,
  } = useQuery<DataDistribution[]>({
    queryKey: ["data_distribution", JSON.stringify(filterState), user.selectedRoles],
    queryFn: () => getDataDistributions(filterState || defaultFilterState, user),
    enabled: !!filterState,
  });

  let data: DataDistribution[] = [];
  if (!isError && !!dataResponse && dataResponse.length > 0) {
    data = dataResponse;
  }

  console.debug("DataDistribution:render", { isLoading, data });

  return (
    <ChartCard
      title={[
        "Data Distribution ",
        ...(isLoading ? [] : [`${compactFormatter.format(getTotalRecords(data))} records`]),
      ]}
      tooltipContent={
        <CommonTooltipContent
          header="Data Distribution"
          desc={
            <>
              This widget surfaces the distribution of text records from the selected data sources, based on your
              applied filters and search. It displays the record count and percentage from each source. When this widget
              is expanded, the Data Freshness and Data Completeness are displayed.
              <br />
              <br />
              Data Freshness represents the last date on which the data was ingested by Oasis, as well as by Deepsense.
              Data freshness can be utilized to validate the insights are up-to-date.
              <br />
              <br />
              Data completeness can be used to determine if a particular field is valuable to filter or if using
              Semantic Search would return more robust results. If the data completeness for a data source is 0%, it is
              likely that field is never captured for that data source.
            </>
          }
        />
      }
      actions={<StyledButton onClick={() => setExpanded(!expanded)}>Show {expanded ? "Less" : "More"}</StyledButton>}
    >
      {!isLoading ? (
        data.length ? (
          <>
            <DataDistributionChart data={data} />
            <Collapse in={expanded}>
              <Box>
                <T fontWeight={600} sx={{ pb: "8px", pt: "36px" }}>
                  Data Freshness
                </T>
                <DataRefreshTable data={data} />
              </Box>

              <Box>
                <T fontWeight={600} sx={{ pb: "8px", pt: "36px" }}>
                  Data Completeness
                </T>
                <DataCompletenessTable data={data} />
              </Box>
            </Collapse>
          </>
        ) : (
          <Box display="flex" flexDirection="row" justifyContent="center">
            <T>No records to show.</T>
          </Box>
        )
      ) : (
        <Skeleton variant="rounded" height={25} />
      )}
    </ChartCard>
  );
};

export default DataDistributionCard;
