import { FormatListBulleted, Subject } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { blue, grey, indigo } from "../../../colors";
import { SummarySettingsAtom } from "./SummarizeDocumentState";

export default function UploadSettingsPanel() {
  const [settings, setSettings] = useAtom(SummarySettingsAtom);

  const styleOptions = [
    {
      icon: Subject,
      title: "Detailed Summary",
      desc: "Paragraph-style summary in flowing prose",
      value: "detailed",
    },
    {
      icon: FormatListBulleted,
      title: "Key Points",
      desc: "Bullet-point summary in bullet points",
      value: "bullets",
    },
  ];

  return (
    <div>
      <Typography>Summary Style</Typography>
      <Box
        sx={{
          marginBottom: "1em",

          "& .MuiSvgIcon-root": {
            fill: indigo[900],
            verticalAlign: "sub",
            fontSize: "2em",
          },

          "& .MuiButton-root": {
            border: `2px solid ${grey[100]}`,
            my: "0.5em",
            p: 0,
            display: "flex",
            flexDirection: "row",
            borderRadius: "1em",

            "& .option-icon": {
              padding: "1.25em",
            },
            "& .option-title": {
              fontWeight: "bold",
              color: indigo[900],
            },
            "& .option-text": {
              flex: 1,
            },
          },

          "& .selected": {
            border: `2px solid ${blue[700]}`,
          },
        }}
      >
        {styleOptions.map((option, i) => {
          const classes = ["summary-style"];

          if (settings.summary_style == option.value) {
            classes.push("selected");
          }

          return (
            <Button
              key={i}
              fullWidth
              className={classes.join(" ")}
              onClick={() => setSettings((_) => ({ ..._, summary_style: option.value }))}
            >
              <Box className="option-icon">
                <option.icon />
              </Box>
              <Box className="option-text">
                <Typography className="option-title">{option.title}</Typography>
                <Typography>{option.desc}</Typography>
              </Box>
            </Button>
          );
        })}
      </Box>

      <Typography>Summary Length</Typography>
      <Box
        sx={{
          my: "0.5em",

          "& .MuiButton-root": {
            justifyContent: "center",
            color: indigo[900],
          },

          "& .length-hint": {
            color: grey[500],
          },

          "& .selected": {
            fontWeight: "bold",
            color: blue[700],
            background: "rgba(11, 65, 205, 0.15)", // aka: blue[700],
          },
        }}
      >
        <ButtonGroup variant="outlined" fullWidth aria-label="Basic button group">
          {["Short", "Medium", "Long"].map((option, i) => (
            <Button
              key={i}
              className={settings.summary_length == option.toLowerCase() ? "selected" : undefined}
              onClick={() => setSettings((_) => ({ ..._, summary_length: option.toLowerCase() }))}
            >
              {option}
            </Button>
          ))}
        </ButtonGroup>
        <Typography className="length-hint">250-500 words</Typography>
      </Box>
    </div>
  );
}
