import { ArrowForward, Settings, UploadFile } from "@mui/icons-material";
import { Box, Button, CircularProgress, InputAdornment, Paper } from "@mui/material";
import { useAtom } from "jotai";
import { MuiFileInput } from "mui-file-input";
import { useState } from "react";
import { grey } from "../../../colors";
import { SummarySettingsModalOpenAtom } from "./SummarizeDocumentState";
import UploadSettingsModal from "./UploadSettingsModal";

export default function FileUploadPanel() {
  const [, setOpen] = useAtom(SummarySettingsModalOpenAtom);
  const [loading] = useState(false);

  const [value, setValue] = useState<File | null>(null);

  const handleChange = (newValue) => {
    console.log({ newValue });
    setValue(newValue);
  };

  const errors: string[] = [];

  if (value && value.size > 5 * 1000 * 1024) {
    errors.push("File selected is too large");
  }

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box sx={{ ml: 1, flex: 1, p: "0.5rem", mt: 1 }}>
        <MuiFileInput
          fullWidth
          value={value}
          error={errors.length > 0}
          onChange={handleChange}
          inputProps={{ accept: ".pdf, .docx" }}
          helperText={
            <>
              {errors.map((_error) => (
                <div>{_error}</div>
              ))}
            </>
          }
          InputProps={{
            startAdornment: !value ? (
              <InputAdornment position="start">
                <UploadFile /> Click or Drag Upload File Here
              </InputAdornment>
            ) : undefined,
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft="1rem">
        <div></div>
        {loading ? (
          <CircularProgress sx={{ p: "0.25em", m: "4px" }} />
        ) : (
          <Box display="flex">
            <Button
              disableElevation
              variant="contained"
              aria-label="settings"
              sx={{ padding: "8px", margin: "4px", minWidth: "unset", color: grey[700] }}
              onClick={() => setOpen(true)}
            >
              <Settings />
            </Button>
            <Button
              disableElevation
              disabled
              variant="contained"
              aria-label="search"
              sx={{ padding: "8px", margin: "4px", minWidth: "unset", color: grey[700] }}
            >
              <ArrowForward />
            </Button>
          </Box>
        )}
      </Box>
      <UploadSettingsModal />
    </Paper>
  );
}
