import { atom } from "jotai";
import { SearchFilters } from "../../../../models/search";

export interface SocketRequest {
  search_request: SearchFilters;
}

export interface SocketResponseInitial {
  composite_user_id: string;
  action_id: string;
  search_object: SearchFilters;
}

export interface SummaryBullet {
  bullet_id: number;
  text: string;
  source_ids: string[];
}

export interface DocumentMapping {
  bullet_id: number;
  document_ids: string[];
}

export interface SocketResponseAnswer {
  answer: string;
  bullets: SummaryBullet[];
}

export type SocketResponse = SocketResponseInitial | SocketResponseAnswer;
export type Summary = Partial<SocketResponseInitial & SocketResponseAnswer & { feedback: 1 | -1 }>;
export type Feedback = Summary;

export const EmptySummary: Summary = {
  bullets: [],
};

export const TriggerExecSummaryAtom = atom(0);

export const ExecSummaryLoadingAtom = atom(false);
export const ExecSummaryEnableTooltipAtom = atom(false);
export const ExecSummaryDisableButtonAtom = atom(false);
export const ExecSummaryHasContentAtom = atom(false);
export const ExecSummaryContentAtom = atom({ ...EmptySummary });
