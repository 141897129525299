import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { atom, useAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import * as DeepchatApi from "../../api/deepchat";
import { ChatResponse, ChatResponseAnswerPartial, SourceDocument } from "../../components/Deepchat/models";
import {
  QuestionCanAcceptFeedback,
  QuestionIsLoadingAtom,
  QuestionTextAtom,
  SessionThreadAtom,
} from "../../components/Deepchat/state/DeepchatState";
import useDeepchat, { WebSocketChatResponse } from "../../contexts/useDeepchat";
import emitSnackbar from "../../emitSnackbar";
import { useHideFilterDrawer } from "../../pages/AppLayout";
import useUserContext from "../../components/Authorization/useUserContext";

const DEEPCHAT_WS_URI = import.meta.env.VITE_APP_DEEPCHAT_WS_URI;

//ATOMS
export const hideFilterAtom = atom(true);
export const disableFilterAtom = atom(false);
export const socketSeedAtom = atom(0);

export const useDeepChatStore = () => {
  const theme = useTheme();
  const user = useUserContext();
  const navigate = useNavigate();
  const { hideFilterDrawer, setHideFilterDrawer } = useHideFilterDrawer();
  const marginTransition = `margin ${theme.transitions.duration.enteringScreen}ms`;
  const Deepchat = useDeepchat();

  //atoms
  const [disableFilters, setDisableFilters] = useAtom(disableFilterAtom);
  const [questionIsLoading, setQuestionIsLoading] = useAtom(QuestionIsLoadingAtom);
  const [sessionThread, setSessionThread] = useAtom(SessionThreadAtom);
  const [, setAllowFeedback] = useAtom(QuestionCanAcceptFeedback);
  const [, setQuestion] = useAtom(QuestionTextAtom);

  const shouldDisableFilters = sessionThread.length > 0 || questionIsLoading;

  // -- Thread State Management
  // -- is this required? As we can directly pass 0
  // const [socketSeed, setSocketSeed] = useAtom(socketSeedAtom);

  const formatChatUrl = (chatAuthCode: string) => {
    return `${DEEPCHAT_WS_URI}?authCode=${chatAuthCode}`;
  };

  //websocket
  const getChartUrl = useCallback(async () => {
    const response = await DeepchatApi.getToken(user);
    return formatChatUrl(response.auth_code);
  }, []);

  const getWebSocket = useCallback(() => useWebSocket<WebSocketChatResponse<unknown>>(getChartUrl), [0]);
  const { lastJsonMessage, sendJsonMessage, readyState } = getWebSocket();

  if (shouldDisableFilters != disableFilters) {
    setDisableFilters(shouldDisableFilters);
  }

  const toggleHideFilter = (value?: boolean) => {
    if (value) {
      setHideFilterDrawer(!hideFilterDrawer);
    } else {
      setHideFilterDrawer(value!);
    }
  };

  //to log websocket data
  useEffect(() => {
    console.debug(
      `[${dayjs().format("HH:mm:ss.SSS")}] The WebSocket is currently: ${SocketConnectionStatus[readyState]}`,
    );
  }, [readyState]);

  useEffect(() => {
    toggleHideFilter(true);
  }, []);

  // This is handling both web socket messages and page state transitions, but I cant tell if its in the wrong place
  useEffect(() => {
    switch (lastJsonMessage?.type) {
      case "chat.initial": {
        const message = lastJsonMessage as WebSocketChatResponse<ChatResponse>;
        const session = Deepchat.getKeyFromSession([message.data]);
        navigate(`/deepchat/threads/${session}`, { replace: true, state: { prevent_load: true } });
        // Handle adding responses to the session thread state object
        setSessionThread((_session) => {
          return [..._session, message.data];
        });

        setQuestion("");
        break;
      }
      case "chat.answer": {
        const message = lastJsonMessage as WebSocketChatResponse<ChatResponseAnswerPartial>;

        if (questionIsLoading) {
          setQuestionIsLoading(false);
        }
        setSessionThread((_session) => {
          const newSessionArray = [..._session];
          const lastInteraction = newSessionArray.at(-1);
          if (lastInteraction && message) {
            lastInteraction.response = message.data.answer;
          }
          return newSessionArray;
        });
        break;
      }
      case "chat.source": {
        const message = lastJsonMessage as WebSocketChatResponse<SourceDocument>;
        setSessionThread((_session) => {
          const newSessionArray = [..._session];
          const lastInteraction = newSessionArray.at(-1);
          if (lastInteraction && message) {
            lastInteraction.sources = [...(lastInteraction.sources ?? []), message.data];
          }
          return newSessionArray;
        });
        break;
      }
      case "chat.complete": {
        setAllowFeedback(true);
        break;
      }
      default: {
        if ((lastJsonMessage as any)?.message === "Internal server error") {
          setQuestionIsLoading(false);
          emitSnackbar("Something went wrong, please try again in a moment", "warning");
        }
      }
    }
  }, [lastJsonMessage]);

  return {
    hideFilterDrawer,
    toggleHideFilter,
    disableFilters,
    lastJsonMessage,
    marginTransition,
    sendJsonMessage,
    readyState,
  };
};

const SocketConnectionStatus = {
  [ReadyState.CONNECTING]: "Connecting",
  [ReadyState.OPEN]: "Open",
  [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  [ReadyState.CLOSING]: "Closing",
  [ReadyState.CLOSED]: "Closed",
};
