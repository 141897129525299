import axios from "axios";
import { FeedbackSubmission, VerbatimFeedback } from "../components/cards/VerbatimsCard/FeedbackState";
import { UserState } from "../components/Authorization/AuthorizationState";
import { buildHeaders } from "./utils";

const API_URI = import.meta.env.VITE_APP_API_URI;

export const getAllUserFeedback = async (user: UserState): Promise<VerbatimFeedback[]> => {
  const response = await axios.get<VerbatimFeedback[]>(`${API_URI}/feedback`, {
    headers: buildHeaders(user),
  });

  return response.data;
};

export const getUserFeedback = async (documentIds: string[], user: UserState): Promise<VerbatimFeedback[]> => {
  const response = await axios.post<VerbatimFeedback[]>(`${API_URI}/feedback/find`, documentIds, {
    headers: buildHeaders(user),
  });

  return response.data;
};

export const addUserFeedback = async (
  payload: FeedbackSubmission<unknown>,
  user: UserState,
): Promise<VerbatimFeedback> => {
  const response = await axios.post<VerbatimFeedback>(`${API_URI}/feedback`, payload, {
    headers: buildHeaders(user),
  });

  return response.data;
};
