import axios, { AxiosError } from "axios";
import { OptionsObject } from "notistack";
import { useCallback } from "react";
import useWebSocket from "react-use-websocket";
import { UserState } from "../components/Authorization/AuthorizationState";
import useUserContext from "../components/Authorization/useUserContext";
import emitSnackbar from "../emitSnackbar";
import { buildHeaders } from "./utils";

interface AuthTokenResponse {
  auth_code: string;
}

interface WebSocketResponse<T> {
  type: string;
  data: T;
}

const snackbarErrorOptions: OptionsObject = {
  anchorOrigin: { horizontal: "center", vertical: "top" },
  autoHideDuration: 15000,
  preventDuplicate: true,
};

const WS_URI = import.meta.env.VITE_APP_DEEPCHAT_WS_URI;
const API_URI = import.meta.env.VITE_APP_API_URI;

const getToken = async (user: UserState) => {
  const response = await axios.get<AuthTokenResponse>(`${API_URI}/chat/token`, {
    headers: buildHeaders(user),
  });
  return response.data;
};

export const useSocket = <Request, Response>(service_requested: string) => {
  const user = useUserContext();
  const getSocketUrl = useCallback(async () => {
    const response = await getToken(user);
    return `${WS_URI}?authCode=${response.auth_code}`;
  }, []);
  const { sendJsonMessage: _sendJsonMessage, ...rest } = useWebSocket<WebSocketResponse<Response>>(getSocketUrl, {
    shouldReconnect: () => true,
  });
  const sendJsonMessage = (user_request: Request) =>
    _sendJsonMessage({ action: "streamChat", service_requested, user_request });

  return { ...rest, sendJsonMessage };
};

export { ReadyState } from "react-use-websocket";

export const usePostFeedback = <Feedback>() => {
  const user = useUserContext();
  const postFeedback = async (feedback: Feedback) => {
    try {
      await axios.put(`${API_URI}/chat/executive-summary/feedback`, feedback, {
        headers: buildHeaders(user),
      });
    } catch (error) {
      const axiosErrorResponseData = (error as AxiosError).response?.data as { detail?: { message?: string } };
      const message = axiosErrorResponseData?.detail?.message ?? "An error ocurred while submitting your feedback.";
      emitSnackbar(message, "error", snackbarErrorOptions);
    }
  };
  return { postFeedback };
};
