import { Skeleton } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";
import { useAtom } from "jotai";
import { GridColumnVisibility } from "../../components/cards/RelevantTextRecordsCard/state/GridColumnVisibility";
import {
  ExpandedRowsAtom,
  VerbatimColumns,
} from "../../components/cards/RelevantTextRecordsCard/state/VerbatimColumns";
import { OverflownTextTooltip, StyledDataGrid } from "../../components/common";
import { useVerbatims } from "../../contexts/VerbatimsProvider";
import { SearchResults, SortDirEnum } from "../../models/search";
import { useEffect } from "react";

const NUM_SKELETON_ROWS = 8;

const buildSkeletonGridRows = (columns: GridColDef[]): GridColDef[] => {
  return columns.map((col) => ({ ...col, renderCell: () => <Skeleton variant="text" width={col.width} /> }));
};

const VerbatimsGrid = (): JSX.Element => {
  const { records, isLoading, sort, updateSort } = useVerbatims();

  const [expanded, setExpanded] = useAtom(ExpandedRowsAtom);
  const [columnVisibilityModel, setColumnVisibilityModel] = useAtom(GridColumnVisibility);
  const [columns] = useAtom(VerbatimColumns);

  // can only support one sort at a time
  const sortKey = sort ? (Object.keys(sort)[0] as keyof SearchResults) : null;
  const currentSortModel: GridSortModel | undefined =
    sortKey && sort ? [{ field: sortKey, sort: sort[sortKey] }] : undefined;

  const handleSortChange = (newSortModel: GridSortModel) => {
    if (!newSortModel.length && sortKey) {
      updateSort(sortKey, null);
    } else {
      updateSort(
        newSortModel[0].field as keyof SearchResults,
        newSortModel[0].sort ? SortDirEnum[newSortModel[0].sort] : null,
      );
    }
  };

  // enhance all columns with a OverflownTextTooltip if a render component is
  // not already specified. Also set width to 200 if one is not defined
  const displayColumns = columns
    .map((col) =>
      col.renderCell
        ? { ...col }
        : {
            ...col,
            renderCell: (params: GridRenderCellParams) => <OverflownTextTooltip text={String(params.value ?? "")} />,
          },
    )
    .map((col) => (col.width ? { ...col } : { ...col, width: 200 }));

  const all_rows = records?.flatMap((r) => {
    // Only inject child records into the record set if the parent row is toggled as "expanded"
    const childRecords = expanded.includes(r._id) ? r.children ?? [] : [];

    return [r, ...childRecords.map((c) => ({ ...c, _parent_id: r._id }))];
  });

  // Clear the current record expansion list on page change
  useEffect(() => {
    setExpanded([]);
  }, [records]);

  return (
    <StyledDataGrid
      sx={{ height: "calc(100vh - 252px)", "& .hidden": { display: "none" } }}
      // TODO: leaving index id in case _id doesn't exist
      rows={
        !isLoading
          ? (all_rows ?? []).map((d, i) => ({ ...d, id: i }))
          : [...Array(NUM_SKELETON_ROWS)].map((_, i) => ({
              id: i,
            }))
      }
      getRowClassName={(params) => {
        return params.row._parent_id ? "child-row" : "";
      }}
      columns={!isLoading ? displayColumns : buildSkeletonGridRows(displayColumns)}
      hideFooterPagination
      hideFooter
      disableSelectionOnClick
      disableColumnFilter
      initialState={{
        sorting: {
          sortModel: currentSortModel,
        },
      }}
      onSortModelChange={handleSortChange}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) => {
        setColumnVisibilityModel(newModel);
      }}
    />
  );
};

export default VerbatimsGrid;
