import { useQuery } from "@tanstack/react-query";
import { getPortfolioQuarters, getPortfolioSummary } from "../api/uslt";
import useUserContext from "../components/Authorization/useUserContext";

export default function useUsltData() {
  const user = useUserContext();

  const PortfolioQuarters = useQuery({
    queryFn: () => getPortfolioQuarters(user),
    queryKey: ["usltPortfolioQuarters", user.selectedRoles],
  });

  const getPortfolioSummaryText = async (quarterKey: string) => {
    return await getPortfolioSummary(user, quarterKey);
  };

  return {
    PortfolioQuarters,
    getPortfolioSummaryText,
  };
}
