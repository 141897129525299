import axios, { AxiosResponse } from "axios";
import {
  ApiResponse,
  DataDistribution,
  EcosystemResponse,
  EmergingTopicsResponse,
  KeywordsResponse,
  RegionResponse,
  SentimentData,
  TopicDistribution,
  DocumentUsageResponse,
  InquiryVolumeBySourceResponse,
  InquiryVolumeOverTimeResponse,
  TopicsNoInfoInquiriesResponse,
} from "../models/datasource";
import { SearchFilters } from "../models/search";
import { UserState } from "../components/Authorization/AuthorizationState";
import { buildHeaders } from "./utils";

const API_URI = import.meta.env.VITE_APP_API_URI;

// type just for the response data, not used outside of the API request
type DataDistributionResponse = {
  data: DataDistribution[];
};

export const getDataDistributions = async (filters: SearchFilters, user: UserState): Promise<DataDistribution[]> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<DataDistributionResponse> = await axios.post(
    `${API_URI}/search/distributions`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data.data;
};

export const getTopicDistribution = async (
  filters: SearchFilters,
  user: UserState,
): Promise<TopicDistribution<unknown>> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<TopicDistribution<unknown>> = await axios.post(
    `${API_URI}/search/topics-sentiments`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getKeywords = async (filters: SearchFilters, user: UserState): Promise<KeywordsResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<KeywordsResponse> = await axios.post(`${API_URI}/search/keywords`, filters, {
    headers: buildHeaders(user),
  });

  return response.data;
};

export const getEmergingTopics = async (filters: SearchFilters, user: UserState): Promise<EmergingTopicsResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set");

  const response: AxiosResponse<EmergingTopicsResponse> = await axios.post(
    `${API_URI}/search/emerging-topics`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getEcosystems = async (filters: SearchFilters, user: UserState): Promise<EcosystemResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<EcosystemResponse> = await axios.post(
    `${API_URI}/search/ecosystem-distribution`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getRegions = async (filters: SearchFilters, user: UserState): Promise<RegionResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<RegionResponse> = await axios.post(`${API_URI}/search/region-distribution`, filters, {
    headers: buildHeaders(user),
  });

  return response.data;
};

export const getSentiment = async (filters: SearchFilters, user: UserState): Promise<ApiResponse<SentimentData>> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<ApiResponse<SentimentData>> = await axios.post(
    `${API_URI}/search/time-series-sentiments`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getDocumentUsage = async (filters: SearchFilters, user: UserState): Promise<DocumentUsageResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<DocumentUsageResponse> = await axios.post(`${API_URI}/search/products`, filters, {
    headers: buildHeaders(user),
  });

  return response.data;
};

export const getInquiryVolumeBySource = async (
  filters: SearchFilters,
  user: UserState,
): Promise<InquiryVolumeBySourceResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<InquiryVolumeBySourceResponse> = await axios.post(
    `${API_URI}/search/time-series-inquiry-source-volume`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getInquiryVolumeOverTime = async (
  filters: SearchFilters,
  user: UserState,
): Promise<InquiryVolumeOverTimeResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<InquiryVolumeOverTimeResponse> = await axios.post(
    `${API_URI}/search/time-series-inquiry-customer-volume`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};

export const getTopicsNoInfoInquiries = async (
  filters: SearchFilters,
  user: UserState,
): Promise<TopicsNoInfoInquiriesResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<TopicsNoInfoInquiriesResponse> = await axios.post(
    `${API_URI}/search/topics-no-information`,
    filters,
    {
      headers: buildHeaders(user),
    },
  );

  return response.data;
};
