import { useAtom } from "jotai";
import { ExpandedRowsAtom } from "../cards/RelevantTextRecordsCard/state/VerbatimColumns";
import { Box } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export default function VerbatimGridRowExpander({ _id }: { _id: string }) {
  const [expanded, setExpanded] = useAtom(ExpandedRowsAtom);

  const toggle = (id: string) => {
    setExpanded((_) => {
      if (_.some((_id) => _id == id)) {
        return _.filter((_id) => _id != id);
      } else return [..._, id];
    });
  };

  return <Box onClick={() => toggle(_id)}>{expanded.includes(_id) ? <ExpandLess /> : <ExpandMore />}</Box>;
}
