import { ThumbDownAlt, ThumbDownAltOutlined, ThumbUpAlt, ThumbUpAltOutlined } from "@mui/icons-material";
import { Box, Paper, PaperProps, Typography as T, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useDeepchat from "../../contexts/useDeepchat";
import { useRetroStore } from "../../store/retros/useRetroStore";
import ChartCard from "../ChartCard";
import { ThreadFeedback, TPortfolioSummary } from "../Deepchat/models";

const formatResponseText = (text: TPortfolioSummary) => {
  const response = (text.summary || "").split("\n").flatMap((segment, i) => {
    const isListItem = segment.startsWith("- ");
    const isBlankLine = !segment;

    if (isListItem) {
      // Clean off the "- " and make element a <li>
      return <li key={i}>{segment.replace(/^(- )/, "")}</li>;
    }
    return (
      <Typography py={isBlankLine ? "0.25em" : 0} key={i}>
        {segment}
      </Typography>
    );
  });

  return response;
};

const RetrosPortfolioSummaryContent = () => (
  <Paper sx={{ p: 2, color: "#13294B" }}>
    <Typography fontWeight={900}>Retros Portfolio Summary</Typography>
    This widget (some example text that we want to display)
    <br />
    <br />
    <Typography fontWeight={900}>Some other title</Typography>
    Additional text to display in the info icon
  </Paper>
);

export default function PortfolioSummaryCard(props: PaperProps) {
  const { summary } = useRetroStore();
  const Deepchat = useDeepchat();
  const [feedback, setFeedback] = useState<ThreadFeedback>({ action_id: undefined, feedback: 0 });

  const handleThumbsUp = () => {
    if (summary.action_id) {
      setFeedback({
        feedback: 1,
        action_id: summary.action_id,
      });
    }
  };
  const handleThumbsDown = () => {
    if (summary.action_id) {
      setFeedback({
        feedback: -1,
        action_id: summary.action_id,
      });
    }
  };

  const ThumbsUpIcon = () => {
    const IconComponent = feedback.feedback === 1 ? ThumbUpAlt : ThumbUpAltOutlined;
    return <IconComponent onClick={handleThumbsUp} />;
  };

  const ThumbsDownIcon = () => {
    const IconComponent = feedback.feedback === -1 ? ThumbDownAlt : ThumbDownAltOutlined;
    return <IconComponent onClick={handleThumbsDown} />;
  };

  useEffect(() => {
    if (feedback.feedback !== 0 && feedback.action_id) {
      Deepchat.submitSessionFeedback(feedback);
    }
  }, [feedback]);

  return (
    <ChartCard
      {...props}
      title="Retros Portfolio Summary"
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      // tooltipContent={<RetrosPortfolioSummaryContent />}
    >
      <Box sx={{ flexDirection: "row", display: "flex", paddingLeft: "2em", paddingRight: "2em", paddingTop: "1em" }}>
        <Box sx={{ display: "flex", flex: 1 }}>
          <T display="inline" fontWeight={500} fontSize={20} color="label.main">
            Summary
          </T>
        </Box>
        <Box sx={{ display: "flex", gap: "0.75em" }}>
          <ThumbsUpIcon />
          <ThumbsDownIcon />
        </Box>
      </Box>
      <Box sx={{ padding: "2em", paddingTop: "1em" }}>{formatResponseText(summary)}</Box>
    </ChartCard>
  );
}
