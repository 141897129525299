import { Box, PaperProps, Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { getTopicsNoInfoInquiries } from "../../api/dataSources";
import ChartCard from "../../components/ChartCard";
import { DownloadToolTipContent, LineChart } from "../../components/charts";
import { ChartMenu, DownloadButton, FullScreen } from "../../components/common";
import { updateTopicSentimentFilters } from "../../components/filters/FilterFunctions";
import { defaultFilterState } from "../../constants/defaultFilterState";
import { useFilterContext } from "../../contexts/FilterProvider";
import { TopicsNoInfoInquiriesResponse } from "../../models/datasource";
import { downloadAsPng } from "../../utils/element-export";
import CommonTooltipContent from "../CommonTooltipContent";
import useUserContext from "../Authorization/useUserContext";

const TopicsNoInfoInquiriesCard = (props: PaperProps): JSX.Element => {
  const user = useUserContext();
  const { filterState, applyFilters } = useFilterContext();

  const ref = useRef<HTMLCanvasElement>(null);
  const [expanded, setExpanded] = useState(false);

  const triggerDownload = () => {
    if (ref.current) {
      downloadAsPng(ref.current, "topics_no_info_inquiries");
    }
  };

  const {
    data: dataResponse,
    isLoading,
    isError,
  } = useQuery<TopicsNoInfoInquiriesResponse>({
    queryKey: ["topics_no_info_inquiries", JSON.stringify(filterState), user.selectedRoles],
    queryFn: () => getTopicsNoInfoInquiries(filterState || defaultFilterState, user),
    enabled: !!filterState,
  });

  const data = !isError && dataResponse?.data ? dataResponse.data : [];
  const selectedTopic = filterState?.visual_filters?.topics_sentiment?.topics ?? "";

  const handleSelectTopic = (topic: string) => {
    if (!filterState) return;

    applyFilters(updateTopicSentimentFilters(filterState, ["topics"], [topic === selectedTopic ? null : topic]));
  };

  return (
    <ChartCard
      {...props}
      title={["Topics—No (Further) Info Inquiries"]}
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      tooltipContent={
        <CommonTooltipContent
          header="Topics—No (Further) Info Inquiries"
          desc={
            <>
              The Topics—No (Further) Info Inquiries widget shows which topics have most responses asking to terminate
              the conversation over the currently-selected date range.
              <br />
              <br />
              <b>How are the topics created?</b>
              <br />
              Deepsense uses machine learning topic modeling to generate topics and keywords. Topic Modeling groups a
              collection of documents based on semantic similarity into clusters of 20 or more similar documents, each
              of which becomes a topic. Documents not fitting into a large enough cluster are not assigned a topic.
              <br />
              <br />
              Within the records constituting each topic, the model can determine the most relevant keywords. These are
              significant words or phrases extracted from the records in that topic group.
              <br />
              <br />
              The topic labels for these groups are created using generative AI. The labeling model is given a few
              representative documents from the topic group and the respective keywords, in order to generate a
              human-readable label. This label describes the collection of semantically similar documents as a whole.
            </>
          }
        />
      }
      actions={
        <ChartMenu
          onDownload={triggerDownload}
          onFullScreen={() => setExpanded(true)}
          downloadToolTip={<DownloadToolTipContent />}
        />
      }
    >
      {isLoading ? (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
          {[1, 2, 3, 4, 5, 6].map((value) => (
            <Skeleton key={value} variant="rounded" height={20} width={"80%"} sx={{ marginTop: "12px" }} />
          ))}
        </Box>
      ) : (
        <FullScreen
          title="Topics—No (Further) Info Inquiries"
          expanded={expanded}
          handleClose={() => setExpanded(false)}
          p={"1em"}
          actions={<DownloadButton action={triggerDownload} />}
        >
          <LineChart
            onSelectTopic={handleSelectTopic}
            selectedTopic={selectedTopic}
            ref={ref}
            data={data.map(({ epoch, topics }) => ({ epoch, ...topics }))}
            isFullScreen={expanded}
          />
        </FullScreen>
      )}
    </ChartCard>
  );
};

export default TopicsNoInfoInquiriesCard;
