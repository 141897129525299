import { usePostFeedback } from "../../../api/useSocket";
import { Feedback, Summary } from "./state/ExecutiveSummaryState";
import useExecutiveSummaryStore from "./useExecutiveSummaryStore";
import { ThumbUp, ThumbUpOutlined, ThumbDown, ThumbDownOutlined, ArticleOutlined } from "@mui/icons-material";
import { Box, Typography, IconButton } from "@mui/material";
import { formatResponseText } from "../../Deepchat/SessionThread/SessionResponseItem";
import { useFilterContext } from "../../../contexts/FilterProvider";
import { updateJoinIdFilter } from "../../filters/FilterFunctions";
import { indigo } from "../../../colors";
import { useVerbatims } from "../../../contexts/VerbatimsProvider";
import { useEffect, useState } from "react";

export default function ExecutiveSummary() {
  const { numRecords } = useVerbatims();
  const { allowFeedback, setSummary, summary } = useExecutiveSummaryStore();
  const { filterState, applyFilters } = useFilterContext();

  const { postFeedback } = usePostFeedback<Feedback>();

  // Solved from incorrect denominator when viewing a sub set up the records
  const [bulletTotal, setBulletTotal] = useState<number | undefined>(undefined);
  useEffect(() => {
    // Only update "denominator" when join_ids are not set
    if ((filterState?.join_ids ?? []).length == 0) {
      setBulletTotal(numRecords);
    }
  }, [numRecords, filterState?.join_ids]);

  const searchByJoinIds = (join_ids: string[]) => {
    if (filterState) {
      applyFilters(updateJoinIdFilter(filterState, join_ids));
    }
  };

  const getPercentValue = (value: number) => {
    return value < 1 ? "<1" : value;
  };

  return (
    <Box>
      {(!!summary.answer || !!summary.bullets?.length) && (
        <Box display="flex" paddingX="2rem" paddingY="1rem" gap="1rem" flexDirection="column">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontSize="20px" color="#13294B">
              Summary
            </Typography>
            {allowFeedback && (
              <Box display="flex" gap="0.5rem">
                <IconButton
                  onClick={() => {
                    const summaryWithFeedback: Summary = { ...summary, feedback: 1 };
                    postFeedback(summaryWithFeedback);
                    setSummary(summaryWithFeedback);
                  }}
                  size="small"
                >
                  {summary.feedback === 1 ? <ThumbUp fontSize="inherit" /> : <ThumbUpOutlined fontSize="inherit" />}
                </IconButton>
                <IconButton
                  onClick={() => {
                    const summaryWithFeedback: Summary = { ...summary, feedback: -1 };
                    postFeedback(summaryWithFeedback);
                    setSummary(summaryWithFeedback);
                  }}
                  size="small"
                >
                  {summary.feedback === -1 ? (
                    <ThumbDown fontSize="inherit" />
                  ) : (
                    <ThumbDownOutlined fontSize="inherit" />
                  )}
                </IconButton>
              </Box>
            )}
          </Box>
          <Box
            color="#13294B"
            sx={{
              "& .bullet-item": {
                border: "2px solid transparent",
                display: "flex",
                flexDirection: "row",
                padding: "0 0.5em",
                marginBottom: "0.5em",
                borderRadius: "0.5em",
                listStylePosition: "inside",
                cursor: "pointer",

                "&:hover": {
                  backgroundColor: indigo[100],
                },

                "&.selected": {
                  border: `2px solid ${indigo[300]}`,
                  backgroundColor: indigo[100],
                },
              },
              "& .bullet-text": {
                flex: 1,
                display: "list-item",
              },
              "& .bullet-text-body": {
                display: "inline-block",
                width: "calc(100% - 2em)",
                verticalAlign: "top",
              },
            }}
          >
            {formatResponseText(summary.answer ?? "")}
            {(summary.bullets ?? [])
              .toSorted((a, b) => a.bullet_id - b.bullet_id)
              .map((_bullet) => {
                const isSelected =
                  _bullet.source_ids.length && _bullet.source_ids.every((_id) => filterState?.join_ids.includes(_id));

                return _bullet.text ? (
                  <Box
                    key={_bullet.bullet_id}
                    className={`bullet-item ${isSelected ? "selected" : ""}`}
                    onClick={() => {
                      searchByJoinIds(isSelected ? [] : _bullet.source_ids);
                    }}
                  >
                    <Box className="bullet-text">
                      <Box className="bullet-text-body">{_bullet.text}</Box>
                    </Box>
                    <Box>
                      {_bullet.source_ids.length > 0 && (
                        <span>
                          <Typography>
                            {_bullet.source_ids.length}/{bulletTotal} -{" "}
                            {getPercentValue(Math.floor((100 * _bullet.source_ids.length) / (bulletTotal ?? 1)))} %
                            <ArticleOutlined sx={{ verticalAlign: "bottom", ml: "0.5em" }} />
                          </Typography>
                        </span>
                      )}
                    </Box>
                  </Box>
                ) : undefined;
              })}
          </Box>
        </Box>
      )}
    </Box>
  );
}
