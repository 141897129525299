import { Box } from "@mui/material";
import { Serie } from "@nivo/line";
import { useQuery } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import { forwardRef, useRef } from "react";
import { getEmergingTopics } from "../../../api/dataSources";
import { updateTopicSentimentFilters } from "../../../components/filters/FilterFunctions";
import { defaultFilterState } from "../../../constants/defaultFilterState";
import { useFilterContext } from "../../../contexts/FilterProvider";
import { EmergingTopic, EmergingTopicsResponse } from "../../../models/datasource";
import { TopicSentimentFilters } from "../../../models/search";
import useUserContext from "../../Authorization/useUserContext";
import EmergingTopicsGraph from "./EmergingTopicsGraph";
import EmergingTopicsInsights from "./EmergingTopicsInsights";

export type EmergingTopicsChartMode = "graph" | "chips";

export interface EmergingTopicsChartProps {
  mode: EmergingTopicsChartMode;
  isFullScreen: boolean;
}

const dateFormat = "YYYY-MM-DD";

const buildTopicGraphData = (
  data: EmergingTopic[],
  startDate: string | null,
  endDate: string | null,
  isSelected: boolean,
): { topicsForDisplay: Serie[]; minDate: Dayjs; maxDate: Dayjs; subtopicsArray: string[] } => {
  const parsedStart = startDate ? dayjs(startDate) : dayjs("2020-01-01"); // default start date
  const parsedEnd = endDate ? dayjs(endDate) : dayjs();
  let subTopicsArray: string[] = []; // default to end right now

  const topicsAndDates: Record<string, Serie> = {};
  let minDate = parsedStart;
  let maxDate = parsedEnd;

  // Sort all topics by their epoch date
  const sortedTopics = data.sort((a, b) => (a.epoch > b.epoch ? 1 : -1));
  // Build a dictionary that combines duplicate topics and merges
  // their epoch and doc counts together to be displayed on the graph.
  sortedTopics.forEach((topic: EmergingTopic) => {
    if (topicsAndDates[topic.topic]) {
      const currentTopic = topicsAndDates[topic.topic];
      const parsedDate = dayjs.unix(topic.epoch);

      topicsAndDates[topic.topic] = {
        ...currentTopic,
        deltaPercent: topic.delta_percent,
        data: [...currentTopic.data, { x: parsedDate.format(dateFormat), y: topic.doc_count }],
      };
      // Update our running max value
      if (maxDate.diff(parsedDate, "day") < 1) {
        maxDate = parsedDate;
      }
    } else {
      const parsedDate = dayjs.unix(topic.epoch);
      const data: any[] = [];
      // The first epoch we come across is the earliest time we have
      // since we've already sorted by epoch time.
      // If the first timestamp is greater than a day from our query start date,
      // then we will push our own initial data point on here to help "normalize" our graph view
      // against our search date range.
      if (parsedDate.diff(parsedStart, "day") >= 1) {
        data.push({
          x: parsedStart.format(dateFormat),
          y: 0,
        });
      }
      // Push on the first epoch point
      data.push({
        x: parsedDate.format(dateFormat),
        y: topic.doc_count,
      });

      topicsAndDates[topic.topic] = {
        id: topic.topic,
        deltaPercent: topic.delta_percent,
        data,
      };
      // Update our running min date value
      if (minDate.diff(parsedDate, "day") > 1) {
        minDate = parsedDate;
      }
    }

    if (topic.subtopics && topic.subtopics.length > 0) {
      subTopicsArray = topic.subtopics;
    }
  });

  return { topicsForDisplay: Object.values(topicsAndDates), minDate, maxDate, subtopicsArray: subTopicsArray };
};

const EmergingTopicsChart = forwardRef(({ mode, isFullScreen = false }: EmergingTopicsChartProps, ref) => {
  const user = useUserContext();
  const { filterState, applyFilters } = useFilterContext();
  const isTopicSeleted = useRef(true);
  const selectedSubTopic = useRef("");

  const { data: dataResponse, isLoading } = useQuery<EmergingTopicsResponse>({
    queryKey: ["emerging_topics", JSON.stringify(filterState), user.selectedRoles],
    queryFn: () => getEmergingTopics(filterState || defaultFilterState, user),
    enabled: !!filterState,
  });

  const updateTopicFilter = (
    filterKey: keyof TopicSentimentFilters,
    value: string | null,
    fromSubTopics: boolean = false,
  ) => {
    if (!filterState) return;
    if (filterState.visual_filters.topics_sentiment["subtopic"] !== undefined) {
      selectedSubTopic.current = "";
      delete filterState.visual_filters.topics_sentiment["subtopic"];
    }
    applyFilters(updateTopicSentimentFilters(filterState, [filterKey], [value], fromSubTopics));
  };

  const emergingTopics = dataResponse?.data ?? [];
  const selectedTopic = filterState?.visual_filters?.topics_sentiment?.topics ?? "";

  const handleTopicClick = (topic: string, isSelected: boolean) => {
    if (isSelected) {
      isTopicSeleted.current = false;
      updateTopicFilter("topics", null);
    } else {
      isTopicSeleted.current = true;
      updateTopicFilter("topics", topic);
    }
  };

  const handleSubTopicClick = (topic: string, isSelected: boolean) => {
    selectedSubTopic.current = topic;
    updateTopicFilter("topics", topic, true);
  };

  if (!filterState) return <></>;

  const { topicsForDisplay, minDate, maxDate, subtopicsArray } = buildTopicGraphData(
    emergingTopics,
    filterState.dates.start,
    filterState.dates.end,
    isTopicSeleted.current,
  );

  if (!isLoading && topicsForDisplay.length == 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <span>No Emerging Topics Available</span>
      </Box>
    );
  }

  return mode === "graph" ? (
    <EmergingTopicsGraph
      ref={ref}
      topics={topicsForDisplay}
      minDate={minDate}
      maxDate={maxDate}
      isLoading={isLoading}
      selectedTopic={selectedTopic}
      handleTopicClick={handleTopicClick}
      isFullScreen={isFullScreen}
    />
  ) : (
    <EmergingTopicsInsights
      ref={ref}
      topics={topicsForDisplay}
      isLoading={isLoading}
      selectedTopic={selectedTopic}
      selectedSubTopic={selectedSubTopic.current}
      handleTopicClick={handleTopicClick}
      subTopicsArray={subtopicsArray}
      handleSubTopicClick={handleSubTopicClick}
    />
  );
});

export default EmergingTopicsChart;
