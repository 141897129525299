import { Box } from "@mui/material";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { FilterDrawer } from "../components";
import { useHideFilterDrawer } from "../pages/AppLayout";
import { TOP_BAR_HEIGHT } from "./AppLayout";
import { DeepChatSocketContext } from "../contexts/DeepChatSocketContext";
import { useDeepChatStore } from "../store/home/useDeepchatStore";
import { useSearchStore } from "../store/useSearchStore";
import useUserContext from "../components/Authorization/useUserContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function DeepchatPage() {
  const user = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.selectedRoles?.includes("uslt_retro")) {
      navigate("/dashboards/retros");
    }
  }, [user]);

  const { applyDefaultSearchState } = useSearchStore();
  applyDefaultSearchState();

  const { hideFilterDrawer, disableFilters, marginTransition, lastJsonMessage, sendJsonMessage, readyState } =
    useDeepChatStore();

  const FilterDrawerMemo = useMemo(() => {
    return (
      <FilterDrawer
        topPadding={50}
        hideFilterDrawer={hideFilterDrawer}
        showApplyFilters={false}
        disableFilters={disableFilters}
      />
    );
  }, [hideFilterDrawer, disableFilters]);

  return (
    <>
      {FilterDrawerMemo}
      <Box
        component="main"
        sx={{
          marginLeft: hideFilterDrawer !== false ? 0 : 37.5,
          p: 0,
          transition: marginTransition,
          flexGrow: 1,
          paddingTop: `${TOP_BAR_HEIGHT}px`,
          height: "100vh",
        }}
      >
        <div style={{ padding: "24px", display: "flex", height: "100%" }}>
          <DeepChatSocketContext.Provider value={{ lastJsonMessage, sendJsonMessage, readyState }}>
            <Outlet context={useHideFilterDrawer()} />
          </DeepChatSocketContext.Provider>
        </div>
      </Box>
    </>
  );
}
