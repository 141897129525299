import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { SearchResults } from "../models/search";
import { Badge, Box } from "@mui/material";
import { Layers, SubdirectoryArrowRight } from "@mui/icons-material";
import { indigo } from "../colors";
import VerbatimGridRowExpander from "../components/charts/VerbatimGridRowExpander";

const FILTER_DATE_FORMAT = "YYYY-MM-DD";

const renderDateCell = (dateStr: string) => {
  const date = dayjs(dateStr);
  return <div>{date.isValid() ? date.format(FILTER_DATE_FORMAT) : ""}</div>;
};

export const commonColumns: GridColDef[] = [
  {
    field: "children",
    headerName: "Related",
    width: 80,
    renderCell: (params: GridRenderCellParams<any, any>) => {
      const childRecords = (params.row?.children ?? []).length;

      // If a row has child records, display the expansion control + record count badge
      if (childRecords > 0) {
        return (
          <>
            <Box sx={{ position: "relative" }}>
              <Badge
                badgeContent={childRecords}
                color="primary"
                overlap="circular"
                sx={{ "& .MuiBadge-badge": { backgroundColor: indigo[300] } }}
              >
                <Layers />
              </Badge>
            </Box>
            <Box sx={{ marginLeft: "1em" }}>
              <VerbatimGridRowExpander _id={params.row._id} />
            </Box>
          </>
        );
      }
      // If a row is a child display and icon
      else if (params.row._parent_id) {
        return <SubdirectoryArrowRight />;
      }
      // Else, normal record, display nothing
      else return undefined;
    },
  },
  {
    field: "source_filter_date",
    headerName: "Date",
    width: 150,
    renderCell: (params: GridRenderCellParams) => renderDateCell(params.value),
  },
  { field: "data_source", headerName: "Data Source", width: 250 },
  { field: "product_molecule", headerName: "Product/Molecule" },
  { field: "indication", headerName: "Indication" },
  { field: "sub_indication", headerName: "Sub-Indication" },
  { field: "therapeutic_area", headerName: "Therapeutic Area" },
  {
    field: "text",
    headerName: "Text",
    sortable: false,
    width: 300,
  },
  { field: "customer_type", headerName: "Customer Type" },
  { field: "account_type", headerName: "Account Type" },
  { field: "persona_name", headerName: "HCP Persona" },
  { field: "corp_account", headerName: "Corp. Account" },
  { field: "region", headerName: "Region" },
  { field: "ecosystem_name", headerName: "Ecosystem Name" },
  { field: "submitted_by", headerName: "Submitted By" },
  { field: "crm_interaction_id", headerName: "CRM Interaction ID" },
  { field: "surveyid", headerName: "VOC ID" },
  { field: "customer_effort_score", headerName: "Survey Score Grouping" },
  { field: "topic1", headerName: "CRM Interaction Topic 1" },
  { field: "topic2", headerName: "CRM Interaction Topic 2" },
  { field: "keywords", headerName: "Keywords" },
  {
    field: "topics",
    headerName: "Topic",
    width: 300,
  },
  {
    field: "subtopic",
    headerName: "Sub-Topic",
    width: 300,
  },
  {
    field: "text_summary",
    headerName: "Summary",
    sortable: false,
    width: 300,
  },
  { field: "sentiment", headerName: "Sentiment" },
  { field: "source_product", headerName: "Source Product" },
  { field: "source_indication", headerName: "Source Indication" },
  { field: "source_customer_type", headerName: "Source Customer Type" },
  { field: "therapeutic_area_imputed", headerName: "Therapeutic Area ML Imputed" },
  { field: "product_molecule_imputed", headerName: "Product/Molecule ML Imputed" },
  { field: "indication_imputed", headerName: "Indication ML Imputed" },
  {
    field: "oasis_load_date",
    headerName: "Oasis Refresh Date",
    renderCell: (params: GridRenderCellParams) => renderDateCell(params.value),
  },
  { field: "account_name", headerName: "Account Name" },
  { field: "conference", headerName: "Conference" },
  {
    field: "_id",
    headerName: "Deepsense ID",
    width: 200,
  },
];

// enhance column render with overflown text tooltip if one is not already defined
export const commercialColumns = (includeStacked: boolean): GridColDef[] => {
  return [
    ...commonColumns.slice(includeStacked ? 0 : 1, 13),
    { field: "customer_source", headerName: "Customer Source" },
    { field: "hcp_descriptor", headerName: "HCP Descriptor" },
    { field: "patient_descriptor", headerName: "Patient Descriptor" },
    { field: "inquiry_type", headerName: "Inquiry Type" },
    { field: "product_url", headerName: "Product URL" },
    { field: "survey_type", headerName: "Survey Type" },
    { field: "target_priority", headerName: "Target Priority" },
    ...commonColumns.slice(13),
  ];
};

export const medicalColumns = (includeStacked: boolean): GridColDef[] => {
  return [
    ...commonColumns.slice(includeStacked ? 0 : 1, 8),
    { field: "crm_discussion_note", headerName: "CRM Discussion Note" },
    { field: "crm_listening_priority_name", headerName: "Listening Priority" },
    ...commonColumns.slice(8, 13),
    { field: "role_type", headerName: "Role Type" },
    ...commonColumns.slice(13, 15),
    { field: "crm_insight_id", headerName: "CRM Insight ID" },
    { field: "inquiry_id", headerName: "Synapse ID" },
    ...commonColumns.slice(15, 25),
    { field: "source_therapeutic_area", headerName: "Source Therapeutic Area" },
    ...commonColumns.slice(25),
  ];
};

export const otherColumns: GridColDef[] = [
  { field: "join_id", headerName: "Deepsense ID" },
  { field: "data_source", headerName: "Data Source" },
  { field: "product_molecule", headerName: "Product / Molecule" },
  { field: "indication", headerName: "Indication" },
  { field: "therapeutic_area", headerName: "Squad Name / Therapeutic Name" },
  { field: "disease_area", headerName: "Disease Area" },
  { field: "outcome_category", headerName: "Outcome Category" },
  { field: "outcome_name", headerName: "Outcome Name" },
  { field: "outcome_description", headerName: "Outcome Description" },
  { field: "feedback_type", headerName: "Feedback Type" },
  { field: "retro_comments", headerName: "Retro Comments" },
  { field: "key_impact_measurement", headerName: "Key Impact Measurement" },
  { field: "date_range", headerName: "Date Range" },
  { field: "portfolio", headerName: "Portfolio" },
  { field: "document_name", headerName: "Document Name" },
  { field: "oasis_load_date", headerName: "Oasis Refresh Date" },
];

export const GetSearchResultIndexType = (records?: SearchResults[]): "medical" | "commercial" | "other" | undefined => {
  return (records?.length ? records[0]._index : undefined) as "medical" | "commercial" | "other" | undefined;
};

export const GetTableColumns = (
  recordIndex: "medical" | "commercial" | "other" | undefined,
  includeStacked: boolean,
): GridColDef[] => {
  // defining which type of columns should be rendered: medical, commercial, or generic
  let columns: GridColDef[] = commonColumns;
  switch (recordIndex) {
    case "medical":
      columns = medicalColumns(includeStacked);
      break;
    case "commercial":
      columns = commercialColumns(includeStacked);
      break;
    case "other":
      columns = otherColumns;
      break;
  }

  return columns;
};

export const GetTableColumnsFromSearchResult = (
  records?: SearchResults[],
  includeStacked: boolean = false,
): GridColDef[] => {
  return GetTableColumns(GetSearchResultIndexType(records), includeStacked);
};
