import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useFeatureFlag from "../../featureFlags/useFeatureFlag";
import FeatureFlag from "../../featureFlags/FeatureFlag";
import { Feature } from "../../featureFlags/featureFlagConfig";

export default function DeepchatNavigationTabs() {
  const StructuredData = useFeatureFlag(Feature.StructuredData);

  const tabs = [
    {
      route: "/deepchat",
      label: "Ask a Question",
    },
    ...(StructuredData.enabled
      ? [
          {
            route: "/deepchat/sales-data",
            label: "Query Sales Data",
          },
        ]
      : []),
    {
      route: "/deepchat/summarize",
      label: "Summarize a Document",
    },
  ];
  const navigate = useNavigate();

  const [value, setValue] = React.useState(tabs.findIndex((t) => t.route === location.pathname));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ pb: "1em", width: "100%", "& .MuiTabs-root": { border: "none" } }}>
      <Tabs value={value} onChange={handleChange} centered>
        {tabs.map((_tab, index) => {
          return <Tab key={index} value={index} label={_tab.label} onClick={() => navigate(_tab.route)} />;
        })}
      </Tabs>
    </Box>
  );
}
