import axios, { AxiosResponse } from "axios";
import { FilterResponse } from "../models/filter";
import { UserState } from "../components/Authorization/AuthorizationState";
import { buildHeaders } from "./utils";

const API_URI = import.meta.env.VITE_APP_API_URI;

export const getFilters = async (user: UserState): Promise<FilterResponse> => {
  if (!API_URI) throw new Error("API URI env variable not set.");

  const response: AxiosResponse<FilterResponse> = await axios.post(`${API_URI}/filters/facets`, null, {
    headers: buildHeaders(user),
  });

  return response.data;
};
