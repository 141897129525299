import { Box, Card, CssBaseline, Grid, SxProps, ThemeProvider, Typography } from "@mui/material";

import { TopBar } from "../components";
import Roles from "../components/Authorization/Roles";
import { Feature } from "../featureFlags/featureFlagConfig";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import IconCommercialRole from "../icons/IconCommercialRole";
import IconMedicalRole from "../icons/IconMedicalRole";
import IconOtherRole from "../icons/IconOtherRole";
import { theme } from "../theme";

type RoleSelectorProps = {
  setUsersDomainRole: (domainRole: string) => void;
  userRoles: string[];
};

const commonStyles: SxProps = {
  backgroundColor: "#F6F6F6",
  width: 380,
  height: 380,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 4,
  mt: 4,
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "rgba(11, 65, 205, 0.1)",
    border: `3px solid rgba(11, 65, 205, 0.5)`,
    ".MuiTypography-root": {
      fontWeight: 700,
    },
  },
};

const commonTextStyles: SxProps = {
  color: "#003087",
  fontSize: 28,
};

const RoleSelectorPage = ({ setUsersDomainRole, userRoles }: RoleSelectorProps): JSX.Element => {
  const retros_feature = useFeatureFlag(Feature.Retros);

  const ROLE_TYPES = [
    {
      name: "Commercial",
      enabled: true,
      roles: [Roles.COMMERCIAL],
      Icon: IconCommercialRole,
      style: { ...commonStyles },
      textStyle: { ...commonTextStyles },
    },
    {
      name: "Medical",
      enabled: true,
      roles: [Roles.MEDICAL],
      Icon: IconMedicalRole,
      style: { ...commonStyles },
      textStyle: { ...commonTextStyles, mt: 2 },
    },
    {
      name: "Operational Excellence",
      enabled: retros_feature.enabled,
      roles: [Roles.USLT_RETRO],
      Icon: IconOtherRole,
      style: { ...commonStyles },
      textStyle: { ...commonTextStyles, mt: 2 },
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopBar hideSearchBar hideLogoutButton />
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        sx={{ width: "100%", padding: "75px 20px", backgroundColor: "white" }}
      >
        <Typography sx={{ fontSize: 24, fontWeight: 400, color: "#13294B", textAlign: "center" }}>
          You have access to multiple data sets. <br></br> Please choose your role:
        </Typography>
        <Grid
          container
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-around"}
          sx={{ maxWidth: 1200 }}
        >
          {ROLE_TYPES.filter((option) => option.enabled && userRoles.includes(option.roles.at(0) ?? "")).map(
            ({ name, roles, Icon, style, textStyle }, i) => (
              <Card
                key={i}
                sx={{ ...style }}
                // Only retain roles based on the selected User Type
                onClick={() => setUsersDomainRole(roles[0])}
              >
                <Icon />
                <Typography textAlign={"center"} sx={{ ...textStyle }}>
                  {name}
                </Typography>
              </Card>
            ),
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default RoleSelectorPage;
