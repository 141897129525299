import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, Divider, Skeleton, Typography as Text, Tooltip } from "@mui/material";
import { Serie } from "@nivo/line";
import { forwardRef } from "react";
import { InsightChip } from "../../../components/common";

interface EmergingTopicsInsightProps {
  topics: Serie[];
  isLoading?: boolean;
  selectedTopic: string;
  selectedSubTopic: string;
  subTopicsArray?: string[];
  handleTopicClick: (topic: string, isSelected: boolean) => void;
  handleSubTopicClick: (topic: string, isSelected: boolean) => void;
}

interface TopicChipProps {
  topic: Serie;
  selectedTopic: string;
  subTopicsArray?: string[];
  handleTopicClick: (topic: string, isSelected: boolean) => void;
}

const TopicChip = ({ topic, selectedTopic, handleTopicClick }: TopicChipProps) => {
  const deltaChange = topic.deltaPercent;
  const deltaDisplay = Math.abs(deltaChange).toFixed(2);
  const Icon = deltaChange > 0 ? ArrowUpwardIcon : deltaChange < 0 ? ArrowDownwardIcon : null;
  const isSelected = topic.id.toString() === selectedTopic;

  return (
    <InsightChip
      selected={isSelected}
      onClick={() => handleTopicClick(topic.id.toString(), isSelected)}
      label={
        <Box display="flex" flexDirection="row" alignItems="center" sx={{ maxWidth: 450 }}>
          <Tooltip title={topic.id}>
            <Text noWrap sx={{ color: isSelected ? "#0B41CD" : "#13294B" }}>
              {topic.id}
            </Text>
          </Tooltip>
          <Text
            sx={{
              color: "rgba(0, 0, 0, 0.65)",
              fontSize: "0.75em",
              margin: "0 0 0 24px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {Icon ? <Icon sx={{ fontSize: "14px", mr: "2px" }} /> : null}
            {deltaDisplay}%
          </Text>
        </Box>
      }
    />
  );
};

const EmergingTopicsInsights = forwardRef(
  (
    {
      topics,
      isLoading,
      selectedTopic,
      selectedSubTopic,
      subTopicsArray,
      handleTopicClick,
      handleSubTopicClick,
    }: EmergingTopicsInsightProps,
    ref,
  ) => {
    // console.log("topics", subTopicsArray);

    return (
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          overflow: "hidden",
        }}
      >
        {isLoading ? (
          [...Array(20).keys()].map((i) => (
            <Skeleton
              key={i}
              variant="rounded"
              height={32}
              width={`${90 + Math.random() * 90}px`}
              sx={{ margin: "0 8px 8px 0" }}
            />
          ))
        ) : (
          <Box>
            {topics.map((topic) => {
              return <TopicChip key={topic.id} {...{ topic, selectedTopic, subTopicsArray, handleTopicClick }} />;
            })}
            {subTopicsArray && subTopicsArray.length > 0 && (
              <Box>
                <Divider
                  sx={{
                    position: "relative",
                    width: `100%`,
                    // NOTE: `margin: {}` and `margin: ""` (or in our case `marginBottom: ""`) do NOT do the same thing
                    // margin: { bottom: 5 }: here will apply a CSS: bottom: 5, attr where we want an actual `margin-bottom: 5px`
                    margin: { left: 10, right: 10 },
                    marginBottom: "10px",
                    marginTop: "10px",
                  }}
                />
                {subTopicsArray.map((sub) => {
                  const isSelected = sub === selectedSubTopic;
                  return (
                    <InsightChip
                      key={sub}
                      selected={false}
                      onClick={() => handleSubTopicClick(sub, isSelected)}
                      label={
                        <Box display="flex" flexDirection="row" alignItems="center" sx={{ maxWidth: 450 }}>
                          <Tooltip title={sub}>
                            <Text noWrap sx={{ color: "#13294B" }}>
                              {sub}
                            </Text>
                          </Tooltip>
                        </Box>
                      }
                    />
                  );
                })}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  },
);

export default EmergingTopicsInsights;
