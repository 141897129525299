import { useAtom } from "jotai";
import { useState, useEffect, useCallback, useRef } from "react";
import { ReadyState } from "react-use-websocket";
import { useSocket } from "../../../api/useSocket";
import { useFilterContext } from "../../../contexts/FilterProvider";
import { useVerbatims } from "../../../contexts/VerbatimsProvider";
import emitSnackbar from "../../../emitSnackbar";
import {
  TriggerExecSummaryAtom,
  ExecSummaryLoadingAtom,
  ExecSummaryEnableTooltipAtom,
  ExecSummaryDisableButtonAtom,
  ExecSummaryHasContentAtom,
  SocketRequest,
  SocketResponse,
  ExecSummaryContentAtom,
  DocumentMapping,
} from "./state/ExecutiveSummaryState";
import { FilterResetAtom } from "../../../contexts/FilterResetAtom";
import { WebSocketChatResponse } from "../../../contexts/useDeepchat";

interface BufferState {
  bullet_index: number; // Which bullet are we currently on
  bullet_map: Map<number, number>; // index, timestamp
  buffer: WebSocketChatResponse<SocketResponse>[];

  // Reference to the last message we processed so we can do a forward seek in the array while working on each index
  last?: WebSocketChatResponse<SocketResponse>;
  processed: number;
}

export default function useExecutiveSummaryStore() {
  const [allowFeedback, setAllowFeedback] = useState(false);
  const [triggerExecSummary, setTriggerExecSummary] = useAtom(TriggerExecSummaryAtom);
  const [execSummaryLoading, setExecSummaryLoading] = useAtom(ExecSummaryLoadingAtom);
  const [execSummaryEnableTooltip, setExecSummaryEnableTooltip] = useAtom(ExecSummaryEnableTooltipAtom);
  const [execSummaryDisableButton, setExecSummaryDisableButton] = useAtom(ExecSummaryDisableButtonAtom);
  const [execSummaryHasContent, setExecSummaryHasContent] = useAtom(ExecSummaryHasContentAtom);
  const [filterReset] = useAtom(FilterResetAtom);

  const { numRecords } = useVerbatims();
  const { filterState } = useFilterContext();

  const { lastJsonMessage, sendJsonMessage, readyState } = useSocket<SocketRequest, SocketResponse>(
    "executive_summary",
  );

  const [summary, setSummary] = useAtom(ExecSummaryContentAtom);

  const [, setTriggerProcessed] = useState(0);
  const buffer = useRef<BufferState>({ bullet_index: 0, bullet_map: new Map(), buffer: [], processed: 0 });

  const requestExecutiveSummary = () => {
    if (!filterState) return;

    setExecSummaryLoading(true);
    sendJsonMessage({ search_request: filterState });
  };

  const resetSummaryState = () => {
    setSummary({});
    setExecSummaryHasContent(false);
    setAllowFeedback(false);
    setExecSummaryLoading(false);

    setTriggerProcessed(0);
    buffer.current = { bullet_index: 0, bullet_map: new Map(), buffer: [], processed: 0 };
  };

  useEffect(() => {
    if (triggerExecSummary > 0) {
      requestExecutiveSummary();
      setTriggerExecSummary(0);
    }
  }, [triggerExecSummary]);

  useEffect(() => {
    if (["chat.answer", "chat.initial"].includes(lastJsonMessage?.type)) {
      setSummary({
        ...summary,
        ...lastJsonMessage?.data,
      });
    } else if (lastJsonMessage?.type === "chat.bullet") {
      const message = lastJsonMessage?.data as unknown as { bullet_id: number; token: string };

      // Only retain non empty token messages
      if (message.token != "") {
        buffer.current.buffer.push(lastJsonMessage);
        buffer.current.bullet_map.set(message.bullet_id, Date.now());
      }
    } else if (lastJsonMessage?.type === "chat.quantifier") {
      const data = lastJsonMessage?.data as unknown as { mapping: DocumentMapping };

      if (data.mapping.document_ids.length) {
        setSummary((_summary) => {
          const summary = { ..._summary };

          // This puts a non null array back in place when the search is changed/cleared, since using a fixed "empty summary" object lead to weird side effects
          if (!summary.bullets) {
            summary.bullets = [];
          }

          // New append based logic so that the backend can stream incomplete batches of document_ids
          const bullet_item = summary.bullets?.find((_bullet) => _bullet.bullet_id == data.mapping.bullet_id);
          if (bullet_item) {
            bullet_item.source_ids = [...bullet_item.source_ids, ...data.mapping.document_ids];
          } else {
            // Force the quantifications into the render stack, even if the respective bullet hasn't been rendered yet
            summary.bullets = [
              ...summary.bullets,
              { bullet_id: data.mapping.bullet_id, text: "", source_ids: [...data.mapping.document_ids] },
            ];
          }

          return summary;
        });
      }
    } else if (lastJsonMessage?.type === "chat.complete") {
      setExecSummaryLoading(false);
      setAllowFeedback(true);
    } else if ((lastJsonMessage as unknown as { message: string })?.message === "Internal server error") {
      emitSnackbar("Something went wrong, please try again in a moment", "warning");
      setExecSummaryLoading(false);
    }
  }, [lastJsonMessage]);

  const processBuffer = () => {
    // The total duration we will keep searching against the current bullet_index before moving on to the next one
    const MAX_BULLET_WAIT = 250;

    // Locate the index of the last message processed
    const offset = buffer.current.buffer.findIndex((_) => _ === buffer.current.last);

    // Try to find the next message for the current bullet_id
    const next_message = buffer.current.buffer.find((item, index) => {
      const _data = item.data as unknown as { bullet_id: number; token: string };
      return index > offset && _data.bullet_id == buffer.current.bullet_index;
    });

    // If we find a message, process it
    if (next_message) {
      const message = next_message.data as unknown as { bullet_id: number; token: string };

      setSummary((_summary) => {
        const summary = { ..._summary };

        if (!summary.bullets) {
          summary.bullets = [];
        }

        const bullet_item = summary.bullets.find((_bullet) => _bullet.bullet_id == message.bullet_id);

        if (!bullet_item) {
          summary.bullets.push({ bullet_id: message.bullet_id, text: message.token, source_ids: [] });
        } else {
          bullet_item.text += message.token;
        }

        // Retain the reference to the processed message
        buffer.current.last = next_message;
        // Increment the processed stack
        buffer.current.processed = buffer.current.processed + 1;

        return summary;
      });
    }
    // Else we didn't find a message, see if we waited long enough to move on to the next bullet
    else {
      const NOW_MS = Date.now();
      const last_bulletUpdate_timestamp = buffer.current.bullet_map.get(buffer.current.bullet_index);

      // If enough time has passed and we haven't seen a new update for the current bullet
      // attempt to update the target index so we can start looking for messages for the next bullet
      if (NOW_MS - (last_bulletUpdate_timestamp ?? NOW_MS) > MAX_BULLET_WAIT && !!buffer.current.last) {
        const bullet_ids = [...buffer.current.bullet_map.keys()].toSorted((a, b) => a - b);
        const next_id = bullet_ids.find((_id) => _id > buffer.current.bullet_index);

        //console.log("Next Key", { bullet_ids, next_id });

        // If we get a new ID then there must be more messages to process
        if (next_id) {
          //console.log("Process Update Index: ", next_id);

          // Update the buffer to target the new ID
          buffer.current.bullet_index = next_id;
          buffer.current.last = undefined;

          setTriggerProcessed((_) => _ + 1);
        } else {
          // Force stop the logic (could have a better way to check for this)
          buffer.current.processed = buffer.current.buffer.length;

          // Do nothing, we ran out of bullets and we waited long enough we don't think anything else is going to stream in
          console.debug("Process End");
        }
      } else {
        // We haven't hit the waiting threshold for the current bullet, kick the process off again to look for a new message
        if (!execSummaryLoading) {
          setTriggerProcessed((_) => _ + 1);
        }
      }
    }
  };

  // Kick off the process with a small delay so that all the tokens don't print super fast from the buffer
  if (buffer.current.processed < buffer.current.buffer.length) {
    setTimeout(() => processBuffer(), Math.random() * 50 + 20);
  }

  useEffect(() => {
    if ((Boolean(summary.answer) || Boolean(summary.bullets?.length)) && !execSummaryHasContent) {
      setExecSummaryHasContent(true);
    }
  }, [summary]);

  // Trigger a clear of the summary when there is any changes to the applied Filters (not including the join_ids parameter)
  useEffect(
    () => {
      resetSummaryState();
    },
    // To avoid resetting the summary on Bullet Selection, we listen to all search parameters except join_ids
    [
      filterState?.base_filters,
      filterState?.visual_filters,
      filterState?.dates,
      filterState?.query,
      filterState?.stack_related,
    ],
  );

  // Force trigger reset on [filterReset]
  useEffect(() => {
    resetSummaryState();
  }, [filterReset]);

  // On state change determine if the Generate Summary button should be enabled
  useEffect(() => {
    const currExecSummaryEnableTooltip =
      (!numRecords || numRecords < 1 || numRecords > 10000) &&
      !execSummaryHasContent &&
      ReadyState[readyState] == "OPEN";

    if (execSummaryEnableTooltip != currExecSummaryEnableTooltip) {
      setExecSummaryEnableTooltip(currExecSummaryEnableTooltip);
    }

    const currExecSummaryDisableButton = execSummaryEnableTooltip || execSummaryLoading;
    if (execSummaryDisableButton != currExecSummaryDisableButton) {
      setExecSummaryDisableButton(currExecSummaryDisableButton);
    }
  }, [readyState, numRecords, execSummaryEnableTooltip, execSummaryLoading]);

  return {
    summary,
    setSummary,
    allowFeedback,
  };
}
