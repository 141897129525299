import { SearchFilters, Sort } from "../models/search";

export const defaultFilterState: SearchFilters = {
  base_filters: {},
  visual_filters: {
    topics_sentiment: {},
    keywords: [],
    inquiry_source_breakdown: [],
  },
  query: null,
  dates: { start: null, end: null },
  dynamic_date_range: null,
  semantic: true,
  join_ids: [],
  stack_related: true,
};

// Even though sort goes into the filter object that is sent to
// the API, it is separated out so that changing the sort of the verbatims
// does not require re-rendering the entire page.
export const defaultSort: Sort | null = null;
