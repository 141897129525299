import axios, { AxiosResponse } from "axios";
import { TPortfolioSummary } from "../components/Deepchat/models";
import { buildHeaders } from "./utils";
import { UserState } from "../components/Authorization/AuthorizationState";

const API_URI = import.meta.env.VITE_APP_API_URI;

export const getPortfolioQuarters = async (user: UserState) => {
  const response: AxiosResponse<string[]> = await axios.get(`${API_URI}/chat/portfolio-summary/get-quarters`, {
    headers: buildHeaders(user),
  });
  return response.data;
};

export const getPortfolioSummary = async (user: UserState, quarterKey: string) => {
  const response: AxiosResponse<TPortfolioSummary> = await axios.get(
    `${API_URI}/chat/portfolio-summary/${quarterKey}`,
    {
      headers: buildHeaders(user),
    },
  );
  return response.data;
};
