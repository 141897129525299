import { Box, Divider, Tooltip } from "@mui/material";
import { AxisTickProps } from "@nivo/axes";
import { Bar, ComputedDatum } from "@nivo/bar";
import { forwardRef } from "react";
import { AutoSizer } from "react-virtualized";
import { TopicsSentiment } from "../../models/datasource";
import { compactFormatter } from "../../utils/numberFormatter";
import ChartTooltip from "./ChartTooltip";

type TopicDistributionChartProps = {
  data: TopicsSentiment[];
  selectedTopic: string;
  selectedSentiment: string | null;
  handleTopicSentimentClick: (
    topic: string,
    isTopicSelected: boolean,
    sentiment?: string,
    isSentimentSelected?: boolean,
    hasSubTopics?: boolean,
  ) => void;
  isFullScreen: boolean;
};

const POSITIVE_COLOR = "#74AA50";
const NEGATIVE_COLOR = "#C8102E";
const NEUTRAL_COLOR = "#8DC8E8";
const CHART_MARGIN_LEFT = 250;

const buildChartData = (data: TopicsSentiment[], isTopicSelected: boolean) => {
  let allTopicsTotal = 0;
  const subTopicsArray: Record<string, number | string>[] = [];

  const chartData = data.reduce((chartData: Record<string, number | string>[], { topic, sentiments, subtopics }) => {
    const sentimentsTotal = sentiments.positive + sentiments.negative + sentiments.neutral;
    if (topic.toLowerCase() === "all") {
      allTopicsTotal = sentimentsTotal;
    }
    chartData.push({
      topic,
      neutral: sentiments.neutral,
      neutralColor: NEUTRAL_COLOR,
      positive: sentiments.positive,
      positiveColor: POSITIVE_COLOR,
      negative: sentiments.negative,
      negativeColor: NEGATIVE_COLOR,
      sentimentsTotal,
    });

    //Check if there are subtopics and add them to the chartData as well
    if (subtopics && isTopicSelected) {
      subtopics.forEach((subtopic) => {
        const subSentimentsTotal =
          subtopic.sentiments.positive + subtopic.sentiments.negative + subtopic.sentiments.neutral;

        // Add each subtopic to the chartData array
        subTopicsArray.push({
          topic: `${subtopic.subtopic}`, // Label to distinguish subtopics
          neutral: subtopic.sentiments.neutral,
          neutralColor: NEUTRAL_COLOR,
          positive: subtopic.sentiments.positive,
          positiveColor: POSITIVE_COLOR,
          negative: subtopic.sentiments.negative,
          negativeColor: NEGATIVE_COLOR,
          sentimentsTotal: subSentimentsTotal,
        });
      });
    }

    return chartData;
  }, []);

  return { chartData, allTopicsTotal, subTopicsArray };
};

const TopicLabel = (
  tick: AxisTickProps<number>,
  selectedTopic: string,
  hasSubTopics: boolean | undefined,
  onClick: (
    topic: string,
    selected: boolean,
    sentiment?: string,
    isSentimentSelected?: boolean,
    hasSubTopics?: boolean,
    fromAll?: boolean,
  ) => void,
) => {
  const tickValue = tick.value.toString();
  const selected = tickValue.toLowerCase() === selectedTopic.toLowerCase();

  // Treat selecting all as removing the selected topic
  const isAllClick = tickValue.toLowerCase() === "all";

  return (
    <g transform={`translate(${0 - 10},${tick.y})`}>
      <Tooltip title={tick.value}>
        <text
          textAnchor="end"
          dominantBaseline="middle"
          style={{
            fill: "#615E83",
            fontWeight: selected ? 800 : 500,
            fontSize: 12,
            cursor: "pointer",
          }}
          onClick={() => {
            if (isAllClick) {
              onClick(selectedTopic, true, undefined, undefined, false, true);
            } else {
              if (!hasSubTopics || selected) {
                onClick(tickValue, selected, undefined, undefined, false, false);
              } else {
                onClick(tickValue, selected, undefined, undefined, true, false);
              }
            }
          }}
        >
          {/* Truncate longer topics with an ellipsis so everything fits on the chart */}
          {tickValue?.length > 35 ? `${tickValue.slice(0, 35)}...` : tickValue}
        </text>
      </Tooltip>
    </g>
  );
};

const TopicTotalLabel = (tick: AxisTickProps<number>, sortedData: Record<string, string | number>[]) => {
  return (
    <g transform={`translate(${tick.x + 10},${tick.y})`}>
      <text
        dominantBaseline="middle"
        style={{
          fill: "#615E83",
          fontSize: 12,
        }}
      >
        {compactFormatter.format(Number(sortedData[tick.tickIndex]?.sentimentsTotal ?? 0))}
      </text>
    </g>
  );
};

const AddDivider = ({ width, marginBottom = "5px" }: { width: number; marginBottom?: string }) => {
  return (
    <Divider
      sx={{
        position: "relative",
        width: width,
        // NOTE: `margin: {}` and `margin: ""` (or in our case `marginBottom: ""`) do NOT do the same thing
        // margin: { bottom: 5 }: here will apply a CSS: bottom: 5, attr where we want an actual `margin-bottom: 5px`
        margin: { left: 10, right: 10 },
        marginBottom: marginBottom,
        marginTop: "10px",
      }}
    />
  );
};

const TopicDistributionChart = forwardRef(
  (
    { data, selectedTopic, selectedSentiment, handleTopicSentimentClick, isFullScreen }: TopicDistributionChartProps,
    ref,
  ) => {
    const { chartData: formattedData, allTopicsTotal, subTopicsArray } = buildChartData(data, selectedTopic != "");
    // Sort the data by highest to lowest sentiment totals and then cap the list to 21 topics
    // The first will be the cumulative "All" and then the top 20 topics
    const sortedData = formattedData.sort((a, b) => (a.sentimentsTotal > b.sentimentsTotal ? 1 : -1)).slice(-21);
    const sortedSubTopicsData = subTopicsArray
      .sort((a, b) => (a.sentimentsTotal > b.sentimentsTotal ? 1 : -1))
      .slice(-21);

    // Collect and remove the "All" data item
    const allDataIndex = sortedData.findIndex(({ topic }) => topic === "All");
    const allDataItem = sortedData.splice(allDataIndex, 1);

    const chartBottomMargin = 50;
    const barChartHeight = 25 * sortedData?.length;
    const cardHeight = barChartHeight + chartBottomMargin + 50;

    console.debug("TopicDistributionChart:render");

    const commonBarProps = {
      indexBy: "topic",
      padding: 0.2,
      keys: ["neutral", "positive", "negative"],
      enableGridX: false,
      enableGridY: false,
      animate: false,
      enableLabel: false,
      visible: true,
      axisLeft: {
        tickSize: 0,
        tickPadding: 15,
        renderTick: (props: AxisTickProps<number>) =>
          TopicLabel(props, selectedTopic, subTopicsArray && subTopicsArray.length > 0, handleTopicSentimentClick),
      },
      axisBottom: { renderTick: () => <></> },
      tooltip: ({ id, value, color }: { id: string | number; value: number; color: string }) => (
        <ChartTooltip
          id={id as string}
          value={value}
          color={color}
          total={allTopicsTotal}
          toolTipWidth={180}
          defaultPos={{ top: 10, right: 0 }}
        />
      ),
      onClick: (data: ComputedDatum<Record<string, string | number>> & { color: string }) => {
        const isSentimentSelected = data.id.toString() === selectedSentiment?.toLowerCase();
        // Check if the user wants to filter on "all" topics,
        // if that is the case, ensure we deselect the current selected topic
        // and then only filter on the sentiment
        if (data.indexValue.toString().toLowerCase() === "all") {
          return handleTopicSentimentClick(selectedTopic, true, data.id.toString(), isSentimentSelected);
        } else {
          // Users can toggle by topic + sentiment here but clicking on a sentiment color should not
          // deselect the currently toggled topic.
          return handleTopicSentimentClick(data.indexValue.toString(), true, data.id.toString(), isSentimentSelected);
        }
      },
      // Since we cannot access the cursor in the SVG, we can target the parent element
      // and then update the cursor to an pointer
      onMouseEnter: () => {
        (document.getElementById("chartsMouse") as HTMLInputElement).style.cursor = "pointer";
      },
      onMouseLeave: () => {
        (document.getElementById("chartsMouse") as HTMLInputElement).style.cursor = "default";
      },
    };

    // NOTE: Yes we are doing a `margin: { top: -5 }` for the second <Bar> chart and adding `marginBottom: "5px"`
    // to the <Divider> splitting the graph to get uniform whitespace above and below it.
    return (
      <Box
        id={"chartsMouse"}
        sx={{
          "& svg": { display: "block" },
          ...(isFullScreen
            ? {}
            : {
                maxHeight: 400,
                overflowY: "auto",
                overflowX: "hidden",
              }),
        }}
      >
        <AutoSizer disableHeight>
          {({ width }) => (
            <>
              <Box ref={ref} width={width}>
                {selectedTopic === "" ? (
                  <>
                    <Bar
                      margin={{ left: CHART_MARGIN_LEFT, right: 120 }}
                      height={25}
                      width={width}
                      data={allDataItem}
                      layout="horizontal"
                      colors={({ id, data }) => String(data[`${id}Color`])}
                      axisRight={{
                        renderTick: (props: AxisTickProps<number>) => TopicTotalLabel(props, allDataItem),
                      }}
                      {...commonBarProps}
                    />
                    <AddDivider width={width} marginBottom="15px" />
                  </>
                ) : null}

                <Bar
                  margin={{ left: CHART_MARGIN_LEFT, right: 120, top: -5 }}
                  height={barChartHeight}
                  width={width}
                  data={sortedData}
                  layout="horizontal"
                  colors={({ id, data }) => String(data[`${id}Color`])}
                  axisRight={{
                    renderTick: (props: AxisTickProps<number>) => TopicTotalLabel(props, sortedData),
                  }}
                  {...commonBarProps}
                />
                {subTopicsArray && subTopicsArray.length > 0 && (
                  <>
                    <Divider
                      sx={{
                        position: "relative",
                        width: width,
                        // NOTE: `margin: {}` and `margin: ""` (or in our case `marginBottom: ""`) do NOT do the same thing
                        // margin: { bottom: 5 }: here will apply a CSS: bottom: 5, attr where we want an actual `margin-bottom: 5px`
                        margin: { left: 10, right: 10 },
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    />
                    <Bar
                      margin={{ left: CHART_MARGIN_LEFT, right: 120, top: -5 }}
                      height={25 * sortedSubTopicsData?.length}
                      width={width}
                      data={sortedSubTopicsData}
                      layout="horizontal"
                      colors={({ id, data }) => String(data[`${id}Color`])}
                      axisRight={{
                        renderTick: (props: AxisTickProps<number>) => TopicTotalLabel(props, sortedSubTopicsData),
                      }}
                      {...commonBarProps}
                    />
                  </>
                )}
              </Box>
              <Box sx={{ height: `${chartBottomMargin}px`, width }}></Box>
            </>
          )}
        </AutoSizer>
      </Box>
    );
  },
);

export default TopicDistributionChart;
