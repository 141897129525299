import { forwardRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { getKeywords } from "../../../api/dataSources";
import { KeywordsResponse } from "../../../models/datasource";
import { defaultFilterState } from "../../../constants/defaultFilterState";
import { useFilterContext } from "../../../contexts/FilterProvider";
import { updateKeywordFilter } from "../../../components/filters/FilterFunctions";
import KeywordViewCloud from "./KeywordViewCloud";
import KeywordViewInsights from "./KeywordViewInsights";
import useUserContext from "../../Authorization/useUserContext";

export type KeywordChartMode = "cloud" | "chips";

export interface KeywordsChartProps {
  mode: KeywordChartMode;
}

const KeywordsChart = forwardRef(({ mode }: KeywordsChartProps, ref) => {
  const user = useUserContext();
  const { filterState, applyFilters } = useFilterContext();
  const selectedWords = filterState?.visual_filters?.keywords ?? [];

  const toggleKeyword = (keyword: string) => {
    if (!filterState) return;

    let newWords: string[] = [];
    if (selectedWords.includes(keyword)) {
      newWords = [...selectedWords].filter((_w) => _w != keyword);
    } else {
      newWords = [...selectedWords, keyword];
    }
    applyFilters(updateKeywordFilter(filterState, newWords));
  };

  const {
    data: dataResponse,
    isLoading,
    isError,
  } = useQuery<KeywordsResponse>({
    queryKey: ["keywords_response", JSON.stringify(filterState), user.selectedRoles],
    queryFn: () => getKeywords(filterState || defaultFilterState, user),
    enabled: !!filterState,
  });

  const keywords = (!isError && dataResponse?.data ? dataResponse.data : []).slice(0, 75);

  console.debug("KeywordsChart:render");

  if (!isLoading && keywords.length == 0) {
    return (
      <Box
        sx={{
          flex: "1",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <span>No Keywords Available</span>
      </Box>
    );
  }

  return mode == "cloud" ? (
    <Box ref={ref}>
      <KeywordViewCloud {...{ keywords, selectedWords, toggleKeyword }} />
    </Box>
  ) : (
    <KeywordViewInsights {...{ keywords, selectedWords, toggleKeyword, isLoading, ref }} />
  );
});

export default KeywordsChart;
