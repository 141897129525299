import { useAtom } from "jotai";
import StandardModal from "../../common/StandardModal";
import { SummarySettingsModalOpenAtom } from "./SummarizeDocumentState";
import UploadSettingsPanel from "./UploadSettingsPanel";

export default function UploadSettingsModal() {
  const [open, setOpen] = useAtom(SummarySettingsModalOpenAtom);

  const handleSubmit = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <StandardModal
      title={<>Document Summary Settings</>}
      open={open}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      hideFormControls={true}
    >
      <UploadSettingsPanel />
    </StandardModal>
  );
}
