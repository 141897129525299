import { Box, Button, Checkbox, FormControlLabel, FormGroup, Stack, styled } from "@mui/material";
import { StyledModal } from "../../../components/common";
import { ColumnSelectModalOpen } from "./state/ColumnSelectModalOpen";
import { useAtom } from "jotai";
import { VerbatimColumns } from "./state/VerbatimColumns";
import { GridColumnVisibility } from "./state/GridColumnVisibility";
import { useEffect, useState } from "react";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";

const BUTTON_WIDTH = "115px";

const StyledCancelButton = styled(Button)({
  //backgroundColor: "rgba(217, 217, 217, 0.25)",
  color: "rgba(0, 0, 0, 0.8)",
  //border: "none",
  justifyContent: "center",
  width: BUTTON_WIDTH,
  // "&:hover": {
  //   backgroundColor: "rgba(217, 217, 217, 0.50)",
  // },
});

const StyledSaveButton = styled(Button)({
  color: "rgba(11, 65, 205, 0.9)",
  fontWeight: 700,
  //backgroundColor: "rgba(11, 65, 205, 0.15)",
  justifyContent: "center",
  width: BUTTON_WIDTH,
  // "&:hover": {
  //   outline: "none",
  //   backgroundColor: "rgba(11, 65, 205, 0.25)",
  // },
});

export default function ColumnSelectModal() {
  const [open, setOpen] = useAtom(ColumnSelectModalOpen);
  const [columns] = useAtom(VerbatimColumns);

  const [columnVisibilityModel, setColumnVisibilityModel] = useAtom(GridColumnVisibility);
  const [newVisibilityModel, setNewVisibilityModel] = useState<GridColumnVisibilityModel>({});

  const onClose = (): void => {
    setOpen(false);
  };

  // On any open/close event of this modal, reset the temp state
  useEffect(() => {
    setNewVisibilityModel(columnVisibilityModel);
  }, [open]);

  const onApply = () => {
    setColumnVisibilityModel(newVisibilityModel);
    onClose();
  };

  const isAllChecked =
    Object.keys(newVisibilityModel).length === 0 || Object.entries(newVisibilityModel).every(([, value]) => value);

  const noColumnsSelected =
    Object.keys(newVisibilityModel).length == columns.length &&
    Object.values(newVisibilityModel).every((isVisible) => !isVisible);

  const hasChanges = JSON.stringify(columnVisibilityModel) != JSON.stringify(newVisibilityModel);

  const toggleAllColumns = () => {
    const newState = isAllChecked ? Object.fromEntries(columns.map((col) => [col.field || "", false])) : {};

    setNewVisibilityModel(newState);
  };

  return (
    <StyledModal
      title="Select Columns"
      headerBox={{ sx: { textAlign: "left" } }}
      open={open}
      handleModalClose={onClose}
      actions={
        <Stack direction="row" spacing={2} justifyContent="right" pr={4}>
          <StyledCancelButton variant="outlined" onClick={onClose}>
            Cancel
          </StyledCancelButton>
          <StyledSaveButton
            variant="outlined"
            onClick={onApply}
            disabled={!hasChanges || (hasChanges && noColumnsSelected)}
          >
            Apply
          </StyledSaveButton>
        </Stack>
      }
    >
      <Box>
        <Box>Select columns to show.</Box>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isAllChecked} onClick={toggleAllColumns} />}
            label="All Columns"
          />
          {columns.map((col, i) => {
            const columnName = col.field || "";
            const columnIsVisible = newVisibilityModel[columnName] || newVisibilityModel[columnName] === undefined;
            return (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    checked={columnIsVisible}
                    onChange={() => {
                      setNewVisibilityModel((_model) => {
                        // MUI Grid expected the column to not exist or be equal to true for a visible state
                        const newModel = { ..._model };

                        if (columnIsVisible) {
                          newModel[columnName] = false;
                        } else {
                          delete newModel[columnName];
                        }

                        return newModel;
                      });
                    }}
                  />
                }
                label={col.headerName}
              />
            );
          })}
        </FormGroup>
      </Box>
    </StyledModal>
  );
}
