import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { getRegions } from "../../../api/dataSources";
import { defaultFilterState } from "../../../constants/defaultFilterState";
import { SearchFilters } from "../../../models/search";
import { GeoFeatureData } from "./EcosystemMap";
import useUserContext from "../../Authorization/useUserContext";

export default function useGetRegionData(filterState: SearchFilters) {
  const user = useUserContext();

  const region_filters = filterState.base_filters?.region || [];

  // Remove Region filter from the copy of the filters we will use to query for regions, so
  // that unselected regions can retain their document count even when not selected.
  const filtersExcludingRegion = ((state) => {
    const filters = { ...state };
    filters.base_filters = { ...filters.base_filters, region: undefined };
    return filters;
  })(filterState);

  const { data: dataResponse, isLoading } = useQuery({
    queryKey: ["region_response", JSON.stringify(filtersExcludingRegion), user.selectedRoles],
    queryFn: () => getRegions(filtersExcludingRegion || defaultFilterState, user),
    enabled: !!filterState,
  });

  const data = useMemo(
    () =>
      (dataResponse?.data || []).map((region: any) => {
        const regionIsAppliedFilter = region_filters.includes(region.name);
        return { id: region.name, rate: region.record_count, selected: regionIsAppliedFilter } as GeoFeatureData;
      }),
    [filterState, dataResponse],
  );

  return { data, isLoading };
}
