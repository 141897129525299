import dayjs from "dayjs";
import { FilterResponse } from "../../models/filter";
import { SearchBase, SearchFilters, SearchResults, TopicSentimentFilters } from "../../models/search";

// FilterFunctions.ts
// This is a collection of static functions to support working with filters that we don't want to
// embed into any specific stateful component that interacts with search [verbatim] filters

/** Strip out any properties that are not a part of the SearchBase definition */
export const getSearchBase = (search: SearchFilters): SearchBase => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { base_filters, visual_filters, query, dates, semantic, dynamic_date_range, join_ids, stack_related, ...rest } =
    search;

  // Having the "right" set of fields ensures that things like hasUnappliedFilterChanges and hasActiveFilters function correctly
  const searchBase: SearchBase = {
    base_filters,
    visual_filters,
    query,
    dynamic_date_range,
    dates,
    semantic,
    join_ids,
    stack_related,
  };
  return searchBase;
};

export const areFiltersSame = (a: SearchFilters, b: SearchFilters) =>
  JSON.stringify(getSearchBase(a)) == JSON.stringify(getSearchBase(b));

const EMPTY_ARR: string[] = [];
export const getFilter = (filterState: SearchFilters, column_name: keyof SearchResults) => {
  if (!filterState) return EMPTY_ARR;

  return filterState.base_filters[column_name] ?? EMPTY_ARR;
};

export const updateTopicSentimentFilters = (
  filters: SearchFilters,
  filterKeys: (keyof TopicSentimentFilters)[],
  values: (string | null)[],
  fromSubTopics?: boolean,
) => {
  // Initialize a copy of the `topics_sentiment`
  const topicSentimentCopy = { ...filters.visual_filters?.topics_sentiment };

  // Iterate through the filter keys and update the `topics_sentiment`
  filterKeys.forEach((key, index) => {
    const value = values[index];
    if (!value) {
      // If no value, delete the key from `topics_sentiment`
      if (fromSubTopics && key === "topics") {
        delete topicSentimentCopy["subtopic"]; // Delete subtopic when fromSubTopics is true
      } else {
        delete topicSentimentCopy[key];
      }
    } else {
      // Set the value for `subtopic` or `topics` based on `fromSubTopics`
      if (fromSubTopics && key === "topics") {
        topicSentimentCopy["subtopic"] = value;
      } else {
        topicSentimentCopy[key] = value;
      }
    }
  });

  // Return the updated filter object
  return {
    ...filters,
    visual_filters: {
      ...filters.visual_filters,
      topics_sentiment: { ...topicSentimentCopy },
    },
  };
};

export const updateKeywordFilter = (filters: SearchFilters, keywords: string[] | undefined) => {
  return { ...filters, visual_filters: { ...filters.visual_filters, keywords: [...(keywords || [])] } };
};

export const updateJoinIdFilter = (filters: SearchFilters, join_ids: string[]) => {
  return { ...filters, join_ids: [...join_ids] } as SearchFilters;
};

export const updateFilter = (
  filters: SearchFilters,
  filterOptions: FilterResponse,
  filterKey: keyof SearchResults,
  value: string[] | null,
) => {
  const filterStateCopy = { ...filters.base_filters };

  if (!value || value.length == 0) {
    delete filterStateCopy[filterKey];
  } else {
    filterStateCopy[filterKey] = [...value];
  }

  syncDataSourceFilterSelection(filterKey, value, filterOptions, filters, filterStateCopy);

  return { ...filters, base_filters: { ...filterStateCopy } };
};

export const toggleFilterValues = (
  filters: SearchFilters,
  filterOptions: FilterResponse,
  filterKey: keyof SearchResults,
  value: string[] | null,
) => {
  const filterStateCopy = { ...filters.base_filters };
  const filterToUpdate = filterStateCopy[filterKey] || [];

  // Go through each value and toggle it on/off based on if its already applied
  (value || []).forEach((filterValueToToggle) => {
    const index = filterToUpdate.indexOf(filterValueToToggle);
    if (index > -1) {
      filterToUpdate.splice(index, 1);
    } else {
      filterToUpdate.push(filterValueToToggle);
    }
  });

  // Sync the new set of values back to the working copy
  if (filterToUpdate.length == 0) {
    // If there aren't any values for the key, remove the key from the filter object
    // Leaving empty arrays causes cache misses since the default state for each filterKey is `undefined`
    delete filterStateCopy[filterKey];
  } else {
    // IMPORTANT: We have to generate a new array here otherwise change detection downstream does not work correctly
    filterStateCopy[filterKey] = [...filterToUpdate];
  }

  syncDataSourceFilterSelection(filterKey, value, filterOptions, filters, filterStateCopy);

  return { ...filters, base_filters: { ...filterStateCopy } };
};

export const toggleStackedRelated = (filters: SearchFilters) => {
  return { ...filters, stack_related: !filters.stack_related };
};

// special case for datasource filter: filters specific to that datasource need to be
// removed when the datasource is deselected
const syncDataSourceFilterSelection = (
  filterKey: keyof SearchResults,
  value: string[] | null,
  filters: FilterResponse | undefined,
  filterState: SearchFilters,
  filterStateCopy: Partial<Record<keyof SearchResults, string[]>>,
) => {
  const currentDatasources = filterState.base_filters.data_source;
  if (filterKey === "data_source" && currentDatasources && (!value || currentDatasources.length > value.length)) {
    const missingDatasource = currentDatasources.filter((d) => !(value || []).includes(d))[0];
    const datasourceFilterKeys =
      filters?.data_source_filters
        .find((filter) => filter.data_source_name === missingDatasource)
        ?.data_source_filters.map((filter) => filter.column_name) || [];
    datasourceFilterKeys.forEach((key) => delete filterStateCopy[key]);
  }
};

// updateQuery handles both the query input and the semantic search value.
// This is to ensure that if a user has entered text into the search bar,
// but has not hit enter (thus submitting), the value in the
// search bar is also submitted.
export const updateQuery = (
  filters: SearchFilters,
  newValue: string | null,
  semanticSearch: boolean,
): SearchFilters => {
  return { ...filters, query: newValue, semantic: semanticSearch };
};

export const updateDateRange = (
  filters: SearchFilters,
  start: string | null,
  end: string | null,
  dynamicLabel?: string | null,
): SearchFilters => {
  return { ...filters, dates: { start, end }, dynamic_date_range: dynamicLabel };
};

export const FILTER_DATE_FORMAT = "YYYY-MM-DD";
export const updateDateRangeDate = (
  filters: SearchFilters,
  start: Date | null,
  end: Date | null,
  dynamicLabel?: string | null,
): SearchFilters => {
  return {
    ...filters,
    dates: {
      start: start ? dayjs(start).format(FILTER_DATE_FORMAT) : null,
      end: end ? dayjs(end).format(FILTER_DATE_FORMAT) : null,
    },
    dynamic_date_range: dynamicLabel,
  };
};
