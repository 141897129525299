import { useAtom } from "jotai";
import { AuthorizationPostResponseAtom, DefaultUserState, UserStateAtom } from "./AuthorizationState";
import Roles from "./Roles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function useAuthorizationManager() {
  const [AuthorizationResponse, setAuthorizationResponse] = useAtom(AuthorizationPostResponseAtom);
  const [UserState, setUserState] = useAtom(UserStateAtom);

  const setSelectedDomain = (domainRole: string) => {
    const rolesWithoutDomains = UserState.assignedRoles.filter(
      (_role) => ![Roles.COMMERCIAL, Roles.MEDICAL, Roles.USLT_RETRO].includes(_role),
    );

    const selectedRoleSet = [domainRole, ...rolesWithoutDomains];

    setUserState((_) => ({
      ..._,
      selectedRoles: selectedRoleSet,
    }));

    return selectedRoleSet;
  };

  const clearSession = () => {
    localStorage.removeItem("Atom:AuthorizationPostResponse");
    localStorage.removeItem("Atom:UserState");
  };

  const logout = () => {
    setUserState((_) => ({ ..._, intent: "logout" }));
  };

  const selectRole = () => {
    setUserState((_) => ({ ..._, intent: "select-role" }));
  };

  useEffect(() => {
    console.debug("UserState", UserState);
  }, [UserState]);

  const hasSelectedRoles = UserState.selectedRoles.length > 0;

  return {
    AuthorizationResponse,
    setAuthorizationResponse,
    UserState,
    setUserState,

    hasSelectedRoles,

    setSelectedDomain,
    logout,
    clearSession,
    selectRole,
  };
}
