import { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useFilterContext } from "./FilterProvider";
import { closeSnackbar } from "notistack";
import emitSnackbar from "../emitSnackbar";
import { getVerbatims } from "../api/newSearch";
import { defaultSort, defaultFilterState } from "../constants/defaultFilterState";
import { SearchResults, Sort, SortDirEnum, SearchRequestResult } from "../models/search";
import useUserContext from "../components/Authorization/useUserContext";

type VerbatimsContextValueType = {
  records: SearchResults[] | undefined;
  sort: Sort | null;
  updateSort: (sortKey: keyof SearchResults, sortDir: SortDirEnum | null) => void;
  isLoading: boolean;
  numRecords: number | undefined;
  pageSize: number;
  start: number;
  setPageSize: (newPageSize: number) => void;
  setStart: (newStart: number) => void;
};

export const VerbatimsContext = createContext<VerbatimsContextValueType | undefined>(undefined);

export const useVerbatims = (): VerbatimsContextValueType => {
  const context = useContext(VerbatimsContext);
  if (!context) {
    throw new Error("useVerbatims must be used within VerbatimsProvider.");
  }
  return context;
};

export const VerbatimsProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const user = useUserContext();
  const { filterState } = useFilterContext();
  // storing sort portion of the filter state within the verbatims provider so that
  // the entire app does not re-render when a new sort is applied.
  const [sort, setSort] = useState<Sort | null>(defaultSort);
  const [verbatimRecords, setVerbatimRecords] = useState<SearchResults[]>();
  const [numRecords, setNumRecords] = useState<number>();
  const [pageSize, setPageSize] = useState(25);
  const [start, setStart] = useState(0);

  const { data: searchRequestResult, isLoading: verbatimsLoading } = useQuery<SearchRequestResult>({
    queryKey: ["search", JSON.stringify(filterState), JSON.stringify(sort), pageSize, start, user.selectedRoles],
    queryFn: () => getVerbatims(filterState || defaultFilterState, sort, start, pageSize, user),
    enabled: !!filterState,
  });

  const zeroRecordsInfo =
    "0 records to display. Please check your filter selections, as selecting mutually exclusive filters will result in no records being returned.";

  const noDataPermissionsNotification =
    "You currently do not have access to any Deepsense data. Please contact your administrator to have you have data access granted";

  useEffect(() => {
    closeSnackbar();
    if (searchRequestResult) {
      setVerbatimRecords(searchRequestResult.records);
      setNumRecords(searchRequestResult.total_records);
      if (searchRequestResult.total_records === 0) {
        if (
          searchRequestResult?.search_request?.permissions &&
          // LEIBNIZ-1844 - If "any data filters are applied that have no permitted fields",
          // eg: { data_source: [] } means no data sources are granted to the user
          Object.entries(searchRequestResult.search_request.permissions.data_filters).some(
            ([, _permittedFieldValues]) => _permittedFieldValues.length == 0,
          )
        ) {
          emitSnackbar(noDataPermissionsNotification, "warning", {
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
            autoHideDuration: 10000,
            preventDuplicate: true,
            TransitionProps: { direction: "up" },
          });
        } else {
          emitSnackbar(zeroRecordsInfo, "info", {
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
            autoHideDuration: 10000,
            preventDuplicate: true,
            TransitionProps: { direction: "up" },
          });
        }
      }
    }
  }, [searchRequestResult]);

  useEffect(() => {
    /*
     * Anytime the filters / query is changed,
     * the user should be taken back to the first page of results
     */
    setStart(0);
  }, [filterState]);

  const updateSort = (sortKey: keyof SearchResults, sortDir: SortDirEnum | null) => {
    if (filterState) {
      if (!sortDir) {
        setSort(null);
      } else {
        setSort({ [sortKey]: sortDir });
      }
      setStart(0);
    }
  };

  const value: VerbatimsContextValueType = {
    records: verbatimRecords,
    sort,
    updateSort,
    isLoading: verbatimsLoading,
    numRecords: numRecords,
    pageSize,
    start,
    setPageSize: (newPageSize: number) => setPageSize(newPageSize),
    setStart: (newStart: number) => setStart(newStart),
  };

  return <VerbatimsContext.Provider value={value}>{children}</VerbatimsContext.Provider>;
};
