import { useQuery } from "@tanstack/react-query";
import { FilterResponse } from "../models/filter";
import { getFilters } from "../api/filters";
import useUserContext from "../components/Authorization/useUserContext";

export default function useFilterOptions() {
  const user = useUserContext();

  return useQuery<FilterResponse>({
    queryKey: ["filters", user.selectedRoles],
    queryFn: () => getFilters(user),
  });
}
