import { atom } from "jotai";

export interface SummarySettings {
  summary_style?: string; // "detailed" | "bullets"
  summary_length?: string; // "short" | "medium" | "long"
}

export const SummarySettingsModalOpenAtom = atom<boolean>(false);

export const SummarySettingsAtom = atom<SummarySettings>({});
