import { useQuery } from "@tanstack/react-query";
import { getSentiment } from "../../../api/dataSources";
import { defaultFilterState } from "../../../constants/defaultFilterState";
import { SearchFilters } from "../../../models/search";
import useUserContext from "../../Authorization/useUserContext";

export default function useGetSentimentOverTimeData(filterState: SearchFilters) {
  const user = useUserContext();

  return useQuery({
    queryKey: ["sentimentOverTime_response", JSON.stringify(filterState), user.selectedRoles],
    queryFn: () => getSentiment(filterState || defaultFilterState, user),
    enabled: !!filterState,
  });
}
