import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AxiosError } from "axios";
import { SnackbarProvider } from "notistack";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import emitSnackbar from "./emitSnackbar";
import { Routes } from "./Routes";

interface ErrorResponse {
  detail: {
    message: string; // "Queries with nested parentheses are not allowed."
    code: number; // 400
  };
}

// setting up QueryClient context
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError<ErrorResponse>;
        const detail = axiosError.response?.data?.detail;
        if (detail) {
          emitSnackbar(detail.message, "error", {
            anchorOrigin: { horizontal: "center", vertical: "top" },
            autoHideDuration: null,
            preventDuplicate: true,
          });
        }
      }
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: 1000000,
      refetchOnMount: false,
      retry: false,
    },
  },
});

const router = createBrowserRouter(Routes);

export default function App() {
  console.debug("env:", import.meta.env.VITE_APP_APP_ENV);
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }} />
      <RouterProvider router={router} />
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
