import { Search } from "@mui/icons-material";
import { Box, InputAdornment, PaperProps, Skeleton, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { getDocumentUsage } from "../../api/dataSources";
import ChartCard from "../../components/ChartCard";
import { DocumentUsageChart } from "../../components/charts";
import { ChartMenu, DownloadButton, FullScreen } from "../../components/common";
import { defaultFilterState } from "../../constants/defaultFilterState";
import { useFilterContext } from "../../contexts/FilterProvider";
import { DocumentUsageResponse } from "../../models/datasource";
import { downloadAsPng } from "../../utils/element-export";
import CommonTooltipContent from "../CommonTooltipContent";
import useUserContext from "../Authorization/useUserContext";

const DocumentUsageCard = (props: PaperProps): JSX.Element => {
  const user = useUserContext();
  const { filterState } = useFilterContext();

  const ref = useRef<HTMLCanvasElement>(null);
  const [expanded, setExpanded] = useState(false);

  const [search, setSearch] = useState("");

  const triggerDownload = () => {
    if (ref.current) {
      downloadAsPng(ref.current, "document_usage");
    }
  };

  const {
    data: dataResponse,
    isLoading,
    isError,
  } = useQuery<DocumentUsageResponse>({
    queryKey: ["document_usage", JSON.stringify(filterState), user.selectedRoles],
    queryFn: () => getDocumentUsage(filterState || defaultFilterState, user),
    enabled: !!filterState,
  });

  const searchFilter = ({ name }: { name: string }) =>
    search
      .toLowerCase()
      .split(" ")
      .every((item) => name.toLowerCase().includes(item.toLowerCase()));
  const data = !isError && dataResponse?.data ? dataResponse.data : [];

  return (
    <ChartCard
      {...props}
      title={["Document Usage", "Number of Responses"]}
      tooltipContent={
        <CommonTooltipContent
          header="Document Usage · Number of Responses"
          desc={
            <>
              The Document Usage widget shows the documents most frequently shared with HCPs and patients, ranked by the
              number of responses those documents have received once shared.
              <br />
              <br />
              The dashboard widget shows only the top 10 documents; for the full list, view the widget in Full Screen
              mode.
            </>
          }
        />
      }
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      actions={
        <>
          <TextField
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
            placeholder="Search by document title…"
            size="small"
            sx={{
              flexGrow: 2,
              marginRight: "1rem",
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "0.25rem !important",
              },
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "#e0e0e0",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#e0e0e0",
                  background: "rgba(11, 65, 205, 0.05)",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <ChartMenu onDownload={triggerDownload} onFullScreen={() => setExpanded(true)} />
        </>
      }
    >
      {isLoading ? (
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
          {[1, 2, 3, 4, 5, 6].map((value) => (
            <Skeleton key={value} variant="rounded" height={20} width={"80%"} sx={{ marginTop: "12px" }} />
          ))}
        </Box>
      ) : (
        <FullScreen
          title="Document Usage"
          expanded={expanded}
          handleClose={() => setExpanded(false)}
          p={"1em"}
          actions={<DownloadButton action={triggerDownload} />}
        >
          <DocumentUsageChart ref={ref} data={data.filter(searchFilter)} isFullScreen={expanded} />
        </FullScreen>
      )}
    </ChartCard>
  );
};

export default DocumentUsageCard;
